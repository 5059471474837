import React, { useEffect, useState, useRef } from "react";
import DashboardWrapper from "../../layout/index";
import BarChart from "../../components/BarChart";
import PieChart from "../../components/PieChart";
import HorizontalBar from "../../components/HorizontalBar";
import HorizontalThreeBar from "../../components/HorizontalThreeBar";
import GuageChart from "../../components/GuageChart";
import VerticalProgressBar from "../../components/VerticalProgressBar";
import getLeaderBoardList, { getDashboardKpiData } from "../../api/dashobard";
import LeaderTable from "../../components/LeaderTable";
import Loader from "../../components/Loader";
import { VisitsSummaryResponse } from "../../api/analytics";
import {
  formatCurrentEndDate,
  formatCurrentStartDate,
  formatNumber,
} from "../../utility";
import PaginationComponent from "../../components/Pagination";
import ActiveMember from "../../assets/active-member.svg";
import InActiveMember from "../../assets/inactive-member.svg";
import Cross from "../../assets/cross.svg";
import OnLeave from "../../assets/on-leave.svg";
import MetricBar from "../../components/MetricBar";
import TimeFrameSelector from "../../components/TimeFrameSelector";
import { formatDate } from "../../utility";
import { useLocation } from "react-router-dom";
import Filter from "../../assets/icons/Filter.svg";

const Dashboard = () => {
  const [leaderData, setLeaderData] = useState<any>([]);
  const [leaderDataList, setLeaderDataList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<any | undefined>(getCurrentDate());
  const [endDate, setEndDate] = useState<any | undefined>(getCurrentDate());
  const [dashobardData, setDashboardData] = useState<any>([]);
  const [dataLoader, setDataLoader] = useState<boolean>(false);
  const [salesOffice, setSelectedSalesOffice] = useState<any>();
  const [salesOfficeId, setSelectedSalesOfficeId] = useState<any>();
  const [salesRegion, setSelectedSalesRegion] = useState<any>();
  const [timeFrame, setSelectTimeFrame] = useState<any>("Today");
  const [regionList, setRegionList] = useState<any>([]);
  const location = useLocation();
  const [emp, setEmployee] = useState<any>(location.state || null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const scrollContainerRef = useRef(null);

  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleSalesOffice = (e: any) => {
    setSelectedSalesOffice(e.target.value);
    const office = leaderData?.filters?.sales_offices?.filter(
      (item: any) => item.name === e.target.value
    );
    // setRegionList()
    if (office) {
      setRegionList(office[0]?.regions);
      setSelectedSalesOfficeId(office[0]?.sales_office_id);
    }
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    checkTimeFrame(e.target.value, endDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    checkTimeFrame(startDate, e.target.value);
  };

  const handleCLear = () => {
    setEmployee("");
    setRegionList([]);
    setSelectedSalesOfficeId([]);
    setSelectedSalesOffice("");
    setSelectedSalesOfficeId("");
    setStartDate(getCurrentDate());
    setEndDate(getCurrentDate());
  };

  const getIndex = (data: any, index: any) => {
    return Number(index + 1);
  };

  const dataForBarChart = [
  
    { visits: "A", value: dashobardData?.visit_metrics?.successful_visits_count
          ? dashobardData?.visit_metrics?.successful_visits_count
          : 0, },
    { visits: "B", value:  dashobardData?.visit_metrics?.collection_visits_count
          ? dashobardData?.visit_metrics?.collection_visits_count
          : 0, },
    { visits: "C", value: dashobardData?.visit_metrics?.failed_visits_count
          ? dashobardData?.visit_metrics?.failed_visits_count
          : 0, },
    { visits: "D", value: dashobardData?.visit_metrics?.total_visits_count
          ?  dashobardData?.visit_metrics?.total_visits_count
          : 0, },
  ];

  const pieChartDataCallPlan = [
    {
      name: "Planned Visits",
      value: dashobardData?.visit_metrics?.planned_visits_count
        ? dashobardData?.visit_metrics?.planned_visits_count
        : 0,
      color: "#00C48C",
      id: 1,
    },
    {
      name: "Unplanned Visits",
      value: dashobardData?.visit_metrics?.unplanned_visits_count
        ? dashobardData?.visit_metrics?.unplanned_visits_count
        : 0,
      color: "#FFAA2A",
      id: 2,
    },
    {
      name: "Pending Planned Visits",
      value: dashobardData?.visit_metrics?.pending_visits_count
        ? dashobardData?.visit_metrics?.pending_visits_count
        : 0,
      color: "#FF4D68",
      id: 3,
    },
  ];

  const getDashboardData = async (data: any) => {
    await getDashboardKpiData(data)
      .then((res) => {
        setDashboardData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLeaderBoardData = async (param: any, data: any) => {
    setIsLoading(true)
    await getLeaderBoardList(param, data)
      .then((res) => {
        setLeaderData(res.data);
        setIsLoading(false)
        if (currentPage === 1) {
          setLeaderDataList(res.data.items);
        } else {
          setLeaderDataList((prevData: any) => [
            ...prevData,
            ...res.data.items,
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false)
      });
  };

  useEffect(() => {
    const data: any = {};
    if (startDate) {
      data["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (startDate) {
      data["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    if (salesOfficeId) {
      data["sales_office_ids"] = [salesOfficeId];
    }
    if (salesRegion) {
      data["regions"] = [salesRegion];
    }
    if (emp) {
      data["subordinate_ids"] = [emp];
    }

    getDashboardData(data);
  }, []);

  const handleApply = () => {
    setOpen(false);
    const data: any = {};
    if (startDate) {
      data["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (startDate) {
      data["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    if (salesOfficeId) {
      data["sales_office_ids"] = [salesOfficeId];
    }
    if (salesRegion) {
      data["regions"] = [salesRegion];
    }
    if (emp) {
      data["subordinate_ids"] = [emp];
    }
    getDashboardData(data);

    const param = {
      page: 1,
      size: 15,
    };

    setCurrentPage(1)
    const dataLeader: any = {};

    if (startDate) {
      dataLeader["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (startDate) {
      dataLeader["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    if (salesOfficeId) {
      dataLeader["sales_office_ids"] = [salesOfficeId];
    }
    if (salesRegion) {
      dataLeader["regions"] = [salesRegion];
    }
    if (emp) {
      dataLeader["subordinate_ids"] = [emp];
    }

    getLeaderBoardData(param, dataLeader);
  };

  useEffect(() => {
    const param = {
      page: currentPage,
      size: 15,
    };

    const data: any = {};

    if (startDate) {
      data["start_date"] = formatCurrentStartDate(new Date(startDate));
    }
    if (startDate) {
      data["end_date"] = formatCurrentEndDate(new Date(endDate));
    }
    if (salesOfficeId) {
      data["sales_office_ids"] = [salesOfficeId];
    }
    if (salesRegion) {
      data["regions"] = [salesRegion];
    }
    if (emp) {
      data["subordinate_ids"] = [emp];
    }

    getLeaderBoardData(param, data);
  }, [currentPage]);

  const checkTimeFrame = (start: any, end: any) => {
    if (start === formatDate(new Date()) && end === formatDate(new Date())) {
      setSelectTimeFrame("Today");
    } else if (
      start === formatDate(new Date().setDate(new Date().getDate() - 1)) &&
      end === formatDate(new Date().setDate(new Date().getDate() - 1))
    ) {
      setSelectTimeFrame("Yesterday");
    } else if (
      start === formatDate(new Date().setDate(1)) &&
      end === formatDate(new Date())
    ) {
      setSelectTimeFrame("Month till date");
    } else {
      setSelectTimeFrame("");
    }
  };

  const handleClose = () => setOpen(false);

  const handleScroll = () => {
    if (leaderData?.total === leaderDataList?.length) {
      return;
    }
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const { scrollTop, clientHeight, scrollHeight } = scrollContainer;
      if (scrollTop + clientHeight >= scrollHeight - 1 && !isLoading) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    console.log(leaderData,'data')
    if (leaderData?.total === leaderDataList?.length) {
      return;
    }
    const scrollContainer: any = scrollContainerRef.current;
    scrollContainer.addEventListener("scroll", handleScroll);
    return () => scrollContainer.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  return (
    <DashboardWrapper className="fixed-dashboard-wrapper">
      <div className="bg-white  ">
        {/* <div className="flex justify-between items-center gap-4 overflow-x-auto p-2 ">
          <div className="flex space-x-4">
            <div className="border-r-2 gap-2 flex flex-col px-2">
              <label className="text-gray-500 pl-1 text-sm font-medium">
                Sales Office
              </label>
              <select
                className="text-base font-medium"
                value={salesOffice}
                onChange={handleSalesOffice}
              >
                <option value={""}>Select sales office</option>
                {leaderData &&
                leaderData?.filters?.sales_offices?.length > 0 ? (
                  leaderData?.filters?.sales_offices.map((office: any) => (
                    <option key={office.name} value={office.name}>
                      {office.name}
                    </option>
                  ))
                ) : (
                  <option>No office available</option>
                )}
              </select>
            </div>
            <div className="border-r-2 gap-2 flex flex-col px-2">
              <label className="text-gray-500 text-sm font-medium pl-1">
                Region
              </label>
              <select
                className="text-base font-medium"
                value={salesRegion}
                onChange={(e) => setSelectedSalesRegion(e.target.value)}
              >
                <option value={""}>Select region</option>
                {regionList?.length > 0 ? (
                  regionList?.map((office: any) => (
                    <option key={office} value={office}>
                      {office}
                    </option>
                  ))
                ) : (
                  <option>No region available</option>
                )}
              </select>
            </div>
            <div className="border-r-2 gap-2 flex flex-col px-2">
              <label className="text-gray-500 text-sm font-medium pl-1">
                Employee Name
              </label>
              <select
                className="text-base font-medium"
                value={emp}
                onChange={(e) => setEmployee(e.target.value)}
              >
                <option value="">Select employee</option>
                {leaderData && leaderData?.filters?.employees?.length > 0 ? (
                  leaderData?.filters?.employees.map((employee: any) => (
                    <option key={employee.emp_id} value={employee.emp_id}>
                      {employee.name_eng}
                    </option>
                  ))
                ) : (
                  <option>No employee available</option>
                )}
              </select>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-500 text-sm font-medium">
                Date Range
              </label>
              <div className="flex gap-2 space-x-2">
                <input
                  type="date"
                  className="text-base font-medium"
                  placeholder="From"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
                <input
                  type="date"
                  className="text-sm"
                  placeholder="To"
                  value={endDate || ""}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>
            <div className="border-l-2 pl-2 ">
              <TimeFrameSelector
                setSelectTimeFrame={setSelectTimeFrame}
                timeFrame={timeFrame}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </div>
          </div>
          <div
            onClick={handleCLear}
            className="text-13 font-normal flex items-center gap-2 md:mr-8 cursor-pointer"
          >
            clear
            <img src={Cross} alt="" />
          </div>
        </div> */}
        {/* <div className=" flex w-full items-center text-13 p-1 text-[#FF4D68] justify-center bg-culightred">
          You have 12 pending request
        </div> */}
      </div>
      <div className="flex gap-6 pb-0 md:pb-0 p-4 md:p-6 w-full lg:flex-row flex-col">
        {dataLoader && (
          <div className="absolute top-[50%] left-[50%] z-50">
            <Loader />
          </div>
        )}
        <div className="w-full">
          <div className="flex justify-between items-center">
            <div className="flex flex-col gap-1 pl-1 pb-3 text-13 font-medium">
              <p>Showing report for</p>
              <p className="text-[20px] font-semibold">
                {timeFrame ? timeFrame : "Selected Date Range"}
              </p>
            </div>
            <div>
              <button
                className="flex items-center px-4 p-3 gap-2 bg-[#FAFAFA] border-2 border-[#EBEBEB]"
                onClick={() => setOpen(true)}
              >
                <img src={Filter} alt="" /> Fliter
              </button>
            </div>
          </div>
          <div className="md:w-full lg:w-[65% ] h-auto lg:h-[calc(100vh-180px)]  lg:overflow-y-scroll flex flex-col gap-2 pr-2 scrollbar scrollbar-thin ">
            <div className="flex justify-between gap-2  w-full flex-col md:flex-row bg-[#F5F5F5] p-2 rounded-md">
              <div className="bg-white border-2 border-purple px-3 py-2 flex flex-col w-full rounded">
                <div className="text-left">
                  <h2 className="text-13 font-bold">Team Count</h2>
                </div>
                <div className="flex justify-center gap-3 items-center pt-1">
                  <div className="flex flex-col w-full rounded">
                    {/* <div className="h-12 w-[6px] rounded-lg bg-teal-400 mr-2"></div> */}
                    <div className="flex justify-between">
                      <p className="font-normal text-13">Preseller</p>
                      <p className="text-xl font-bold">
                        {formatNumber(
                          dashobardData?.team_metrics?.total_preseller_count || 0
                        )}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="font-normal text-13">Salesman</p>
                      <p className="text-xl font-bold">
                        {formatNumber(
                          dashobardData?.team_metrics?.total_salesman_count || 0
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-center min-w-[80px] border-l-2">
                    {/* <div className="h-12 w-[6px] rounded-lg bg-purple mr-2"></div> */}
                    <p className="text-13  font-normal	">Total</p>
                    <p className="text-purple text-[39px] font-bold">
                      {formatNumber(
                        dashobardData?.team_metrics?.total_preseller_count +
                          dashobardData?.team_metrics?.total_salesman_count  || 0
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className=" bg-green-50 p-3 w-full rounded border-cugreen border-2">
                <div className="text-left">
                  <h2 className="text-green-600 text-base font-semibold">
                    Active Members
                  </h2>
                  <div className="flex justify-between pt-1">
                    <p className=" text-green-600 text-[39px] font-bold mt-2">
                      {formatNumber(
                        dashobardData?.team_metrics?.total_active_count || 0
                      )}
                    </p>
                    <img src={ActiveMember} alt="" />
                  </div>
                </div>
              </div>

              <div className=" bg-red-50 p-4 w-full rounded  border-[#FF4D68] border-2">
                <div className="text-left">
                  <h2 className="text-red-600 text-base	 font-semibold">
                    Inactive Members
                  </h2>
                </div>
                <div className="flex justify-between pt-1">
                  <p className="text-[39px] font-bold">
                    {formatNumber(
                      dashobardData?.team_metrics?.total_inactive_count || 0
                    )}
                  </p>
                  <img src={InActiveMember} alt="" />
                </div>
              </div>

              <div className="bg-blue-50 p-4 w-full rounded border-2 border-[#408BFC]">
                <div className="text-left">
                  <h2 className="text-blue-600 text-base font-semibold">
                    Employees on leave
                  </h2>
                </div>
                <div className="flex justify-between pt-1">
                  <p className=" text-blue-600 text-[39px] font-bold">
                    {formatNumber(
                      dashobardData?.team_metrics?.total_on_leave_count || 0
                    )}
                  </p>
                  <img src={OnLeave} alt="" />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 bg-[#F5F5F5] p-2 rounded">
              <div className="bg-white border-2 p-4 flex flex-col rounded">
                <h2 className="text-[13px] font-semibold">
                  Call Plan Adherence
                </h2>
                <div className="flex pt-2">
                  <div className="flex flex-col w-[30%] gap-2">
                    <div className="flex flex-col">
                      <p className="font-semibold">
                        <span className="text-[31px]">
                          {formatNumber(
                            dashobardData?.visit_metrics?.no_of_visits_count || 0
                          )}
                        </span>
                        <span className="text-[20px]">
                          /
                          {formatNumber(
                            dashobardData?.visit_metrics?.total_visits_count || 0
                          )}
                        </span>
                      </p>
                      <p className="text-[13px] font-semibold">No. of Visits</p>
                    </div>
                    <div className="flex flex-col">
                      <p className="text-cugreen text-[25px] font-bold">
                        {formatNumber(
                          dashobardData?.visit_metrics?.planned_visits_count || 0
                        )}
                      </p>{" "}
                      <p className="text-[13px] font-semibold">
                        Planned Visits
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="text-[#FFAA2A] text-[25px] font-bold">
                        {formatNumber(
                          dashobardData?.visit_metrics?.unplanned_visits_count || 0
                        )}
                      </p>{" "}
                      <p className="text-[13px] font-semibold">
                        Unplanned Visits
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="text-[#FF4D68] text-[25px] font-bold">
                        {formatNumber(
                          dashobardData?.visit_metrics?.pending_visits_count || 0
                        )}
                      </p>{" "}
                      <p className="text-[13px] font-semibold">
                        Pending Planned Visits
                      </p>
                    </div>
                  </div>
                  <div className="h-[300px] w-[70%] flex justify-center">
                    <PieChart data={pieChartDataCallPlan} />
                  </div>
                </div>
              </div>

              <div className="bg-white p-4 border-2 flex flex-col rounded">
                <h2 className="text-[13px] font-bold mb-4">Visits Ratio</h2>{" "}
                <div className="h-[200px] w-full mb-4">
                  <BarChart
                    data={dataForBarChart}
                    barColors={["#00C48C", "#FFAA2A", "#FF4D68" , '#408BFC']}
                  />
                </div>
                <div className="flex flex-col justify-center gap-2">
                  <div className="flex justify-between">
                    <div className="flex ">
                      <div
                        className="h-4 w-4 rounded-full"
                        style={{ backgroundColor: "#00C48C" }}
                      ></div>
                      <span className="ml-2 text-[13px]">
                        Successful Visits
                      </span>
                    </div>
                    <span className="ml-2 text-base">
                      {formatNumber(
                        dashobardData?.visit_metrics?.successful_visits_count || 0
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex ">
                      <div
                        className="h-4 w-4 rounded-full"
                        style={{ backgroundColor: "#FFAA2A" }}
                      ></div>
                      <span className="ml-2 text-[13px]">
                        Collection Visits
                      </span>
                    </div>
                    <span className="ml-2 text-base">
                      {formatNumber(
                        dashobardData?.visit_metrics?.collection_visits_count || 0
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex ">
                      <div
                        className="h-4 w-4 rounded-full"
                        style={{ backgroundColor: "#FF4D68" }}
                      ></div>
                      <span className="ml-2 text-[13px]">Failed Visits</span>
                    </div>
                    <span className="ml-2 text-base">
                      {formatNumber(
                        dashobardData?.visit_metrics?.failed_visits_count || 0
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex ">
                      <div
                        className="h-4 w-4 rounded-full"
                        style={{ backgroundColor: "#408BFC" }}
                      ></div>
                      <span className="ml-2 text-[13px]">Total Visits</span>
                    </div>
                    <span className="ml-2 text-base">
                      {formatNumber(
                      dashobardData?.visit_metrics?.total_visits_count || 0
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-[#F5F5F5] p-2 rounded-md flex flex-col gap-4">
              <div className="bg-white p-4 border-2 flex flex-col gap-2 rounded">
                <h2 className="text-13 font-bold  ">Failed Visits Reasons</h2>
                <MetricBar
                  text="01 Shop Permanent Closed"
                  count={formatNumber(
                    dashobardData?.visit_metrics?.shop_permanently_closed_count || 0
                  )}
                  color={"#408BFC"}
                  maxCount={100}
                />
                <MetricBar
                  text="02 Shop Temporary Closed"
                  count={formatNumber(
                    dashobardData?.visit_metrics?.shop_temporarily_closed_count || 0
                  )}
                  color={"#00C48C"}
                  maxCount={100}
                />
                <MetricBar
                  text="03 Collection issue"
                  count={formatNumber(
                    dashobardData?.visit_metrics?.collection_issue_count || 0
                  )}
                  color={"#BE52F2"}
                  maxCount={100}
                />
                <MetricBar
                  text="04 High Stock"
                  count={formatNumber(
                    dashobardData?.visit_metrics?.high_stock_count || 0
                  )}
                  color={"#FFAA2A"}
                  maxCount={100}
                />
                <MetricBar
                  text="05 Other"
                  count={formatNumber(
                    dashobardData?.visit_metrics?.other_count || 0
                  )}
                  color={"#FF4D68"}
                  maxCount={100}
                />
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 ">
                <div className="bg-white p-4 border-2 flex flex-col ">
                  <h2 className="text-13 font-bold mb-4">
                    Number of Unique Accounts Served
                  </h2>
                  <div className="flex justify-between">
                    <div className="flex flex-col justify-center">
                      <div className="flex flex-col items-start">
                        <div className="text-[31px] font-bold">
                          {formatNumber(
                            dashobardData?.visit_metrics
                              ?.unique_accounts_served_count || 0
                          )}{" "}
                          <span className="text-[20px] font-semibold">
                            /{" "}
                            {formatNumber(
                              dashobardData?.visit_metrics
                                ?.unique_accounts_pending_count || 0
                            )}
                          </span>
                        </div>
                        <div className="text-13 font-semibold">
                          Accounts Served
                        </div>
                      </div>
                      <div className="flex flex-col items-start mt-4">
                        <div className="text-3xl font-bold text-red-500">
                          {formatNumber(
                            dashobardData?.visit_metrics
                              ?.unique_accounts_pending_count || 0
                          )}
                        </div>
                        <div className="text-13 font-semibold">
                          Pending Accounts
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center w-[75%]">
                      <div className=" w-full">
                        <GuageChart
                          served={
                            dashobardData?.visit_metrics
                              ?.unique_accounts_served_count
                              ? formatNumber(
                                  dashobardData?.visit_metrics
                                    ?.unique_accounts_served_count
                                )
                              : 0
                          }
                          pending={
                            dashobardData?.visit_metrics
                              ?.unique_accounts_pending_count
                              ? formatNumber(
                                  dashobardData?.visit_metrics
                                    ?.unique_accounts_pending_count
                                )
                              : 0
                          }
                          target={
                            dashobardData?.visit_metrics
                              ?.unique_accounts_target_count
                              ? formatNumber(
                                  dashobardData?.visit_metrics
                                    ?.unique_accounts_target_count
                                )
                              : 0
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center mt-4">
                    <div className="flex items-center mx-4">
                      <div
                        className="h-4 w-4 rounded-full"
                        style={{ backgroundColor: "#66C2A5" }}
                      ></div>
                      <span className="ml-2 text-xs">Accounts Served</span>
                    </div>
                    <div className="flex items-center mx-4">
                      <div
                        className="h-4 w-4 rounded-full"
                        style={{ backgroundColor: "#FC8D62" }}
                      ></div>
                      <span className="ml-2 text-xs">Pending Accounts</span>
                    </div>
                  </div>
                </div>

                <div className="bg-white p-4 border-2 flex flex-col ">
                  <h2 className="text-13 font-bold mb-4">Sales Achievement</h2>
                  <div className="flex justify-between">
                    <div className="flex flex-col justify-center">
                      <div className="flex flex-col items-start">
                        <div className="text-4xl font-bold">
                          {formatNumber(
                            dashobardData?.visit_metrics
                              ?.total_cartons_sold_count
                          )}{" "}
                        </div>
                        <div className="text-13 font-semibold">Cartons</div>
                      </div>
                      <div className="flex flex-col items-start mt-4">
                        <div className="text-3xl font-bold text-red-500">
                          {formatNumber(
                            dashobardData?.visit_metrics?.pending_cartons_count
                          )}
                        </div>
                        <div className="text-13 font-semibold">
                          Pending Accounts
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center w-[75%]">
                      <div className=" w-full">
                        <GuageChart
                          served={formatNumber(
                            dashobardData?.visit_metrics
                              ?.total_cartons_sold_count
                          )}
                          pending={formatNumber(
                            dashobardData?.visit_metrics?.pending_cartons_count
                          )}
                          target={formatNumber(
                            dashobardData?.visit_metrics
                              ?.total_cartons_target_count
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-4">
                    <div className="flex items-center mx-4">
                      <div
                        className="h-4 w-4 rounded-full"
                        style={{ backgroundColor: "#66C2A5" }}
                      ></div>
                      <span className="ml-2 text-xs">Accounts Served</span>
                    </div>
                    <div className="flex items-center mx-4">
                      <div
                        className="h-4 w-4 rounded-full"
                        style={{ backgroundColor: "#FC8D62" }}
                      ></div>
                      <span className="ml-2 text-xs">Pending Accounts</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full flex-col  md:flex-col lg:flex-row  gap-2 ">
              <div className="flex gap-2 flex-col md:w-full lg:w-[70%]">
                <div className="flex gap-4 bg-[#F5F5F5] p-2 rounded w-full flex-col md:flex-row">
                  <div className="bg-white p-4 border-2 flex flex-col w-full md:w-1/2 min-h-[250px] rounded flex-1">
                    <h2 className="text-13 font-bold mb-4">COD Percentage</h2>
                    <div className="flex flex-row justify-between">
                      <div className="w-[100%]">
                        <GuageChart
                          value={formatNumber(
                            dashobardData?.visit_metrics?.cod_percentage
                          )}
                          target={100}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border border-gray-300 w-full md:w-1/2 rounded p-4 bg-white min-h-[250px] flex-1">
                    <h2 className="text-13 font-bold">COD Orders</h2>
                    <div className="flex justify-between items-center">
                      <div className=" p-4">
                        <p className="text-13 font-medium">Total Orders</p>
                        <p className="font-bold text-[31px]">
                          {formatNumber(
                            dashobardData?.visit_metrics?.total_cod_orders_count
                          )}
                        </p>
                      </div>
                      <div className="border-2 w-[2px] h-12"></div>
                      <div className="border-gray-300 p-4 text-13">
                        <p>Carton Quantity</p>
                        <p className="font-bold text-[31px]">
                          {formatNumber(
                            dashobardData?.visit_metrics?.cod_cartons_qty
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="border-t border-gray-300 rounded p-4">
                      <p className=" text-13 font -bold">Order Value</p>
                      <p className="text-[31px] font-bold text-green-500">
                        {formatNumber(
                          dashobardData?.visit_metrics?.total_cod_order_value
                        ) + " "}
                        SAR
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 bg-[#F5F5F5] w-full p-2 rounded flex-col md:flex-row">
                  <div className="bg-white p-4 border-2 flex flex-col w-full  md:w-1/2  min-h-[250px] rounded flex-1">
                    <h2 className="text-13 font-bold mb-4">
                      Average SKU/Invoice
                    </h2>
                    <div className="flex flex-row justify-between">
                      <div className="w-[95%]">
                        <GuageChart
                          value={formatNumber(
                            dashobardData?.visit_metrics
                              ?.average_sku_per_invoice
                          )}
                          target={4} // todo:static value added
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border border-gray-300 rounded bg-white w-full  min-h-[250px] p-4 flex-1">
                    <h2 className="text-13 font-bold">Bundle Orders</h2>
                    <div className="flex justify-between items-center mb-2">
                      <div className=" p-4 text-sm">
                        <p>Total Orders</p>
                        <p className="font-bold text-3xl">
                          {formatNumber(
                            dashobardData?.visit_metrics
                              ?.total_bundle_orders_count
                          )}
                        </p>
                      </div>
                      <div className="border-2 w-[2px] h-12"></div>
                      <div className="border-gray-300  p-4 text-sm">
                        <p>Carton Quantity</p>
                        <p className="font-bold text-3xl">
                          {formatNumber(
                            dashobardData?.visit_metrics?.bundle_cartons_qty
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="border-t border-gray-300 rounded p-4">
                      <p className="text-13 font-medium">Order Value</p>
                      <p className="text-3xl font-bold text-purple">
                        {formatNumber(
                          dashobardData?.visit_metrics?.total_bundle_order_value
                        ) + " "}
                        SAR
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 bg-[#F5F5F5] p-2 rounded flex-col md:flex-row">
                  <div className=" border-2 w-full p-2 rounded bg-white">
                    <div className="px-3 p-2 font-bold text-13">
                      Total Carton Sales
                    </div>
                    <VerticalProgressBar
                      firstAmount={formatNumber(
                        dashobardData?.visit_metrics
                          ?.preseller_cartons_sold_count
                      )}
                      secondAmount={formatNumber(
                        dashobardData?.visit_metrics
                          ?.salesman_cartons_sold_count
                      )}
                    />
                  </div>
                  <div className=" border-2 w-full  p-2 rounded bg-white">
                    <div className="px-3 p-2 font-bold text-13">
                      Total SAR Sales
                    </div>
                    <VerticalProgressBar
                      firstAmount={formatNumber(
                        dashobardData?.visit_metrics?.preseller_sale_value
                      )}
                      secondAmount={
                        formatNumber(dashobardData?.visit_metrics?.salesman_sale_value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-4 bg-[#F5F5F5] flex-col md:w-full lg:w-[30%] p-2  rounded">
                <div className="border border-gray-300 rounded p-4 bg-white">
                  <h2 className="text-sm font-medium">
                    Total Invoice/PO Generated
                  </h2>
                  <div className="pt-4">
                    <HorizontalBar
                      invoiceValue={
                        formatNumber(dashobardData?.visit_metrics?.total_invoices_count)
                      }
                      poValue={
                        formatNumber(dashobardData?.visit_metrics
                          ?.total_purchase_orders_count)
                      }
                      firstName="Invoice"
                      secondName="PO"
                    />
                  </div>
                </div>
                <div className="bg-white p-2 border-2 flex justify-between rounded items-center px-4 min-h-[120px]">
                  <h2 className="text-13 font-bold ">Drop Size</h2>{" "}
                  <p className="text-purple font-bold text-[39px]">
                    {formatNumber(dashobardData?.visit_metrics?.drop_size_count)}
                  </p>
                </div>
                <div className="bg-white p-4 border-2 flex justify-between items-center  min-h-[120px] rounded">
                  <h2 className="text-13 font-bold ">
                    Average Cartons Per Route
                  </h2>{" "}
                  {/* Title with bold text */}
                  <p className="text-purple font-bold  text-[39px]">
                    {formatNumber(dashobardData?.visit_metrics?.average_carton_per_route)}
                  </p>
                </div>
                <div className="bg-white p-4 border-2 flex flex-col rounded">
                  <h2 className="text-13 font-bold">Collected Amount</h2>{" "}
                  <div className="flex flex-row justify-between">
                    <p className="font-bold text-3xl text-green-400 pt-2">
                      {formatNumber(dashobardData?.visit_metrics?.total_collected_amount)} SAR
                    </p>
                  </div>
                  <div className="border-t-2 flex justify-between pt-2">
                    {/* todo */}
                    <div>
                      <p className="text-16 font-bold">
                        {" "}
                        {
                          formatNumber(dashobardData?.visit_metrics
                            ?.total_collected_amount_by_card)
                        }{" "}
                        SAR
                      </p>
                      <p className="text-13 font-semibold">By card</p>
                    </div>
                    <div className="border-l-2 pl-8">
                      <p className="text-16 font-bold">
                        {" "}
                        {
                          formatNumber(dashobardData?.visit_metrics
                            ?.total_collected_amount_by_cash)
                        }{" "}
                        SAR
                      </p>
                      <p className="text-13 font-semibold">By cash</p>
                    </div>
                  </div>
                </div>
                <div className="border border-gray-300 rounded p-4 bg-white">
                  <h2 className="text-13 font-bold">New Customer Created</h2>
                  <div className="mt-4">
                    <HorizontalThreeBar
                      firstValue={
                        formatNumber(dashobardData?.visit_metrics
                          ?.new_customers_created_approved_count)
                      }
                      secondValue={
                       formatNumber( dashobardData?.visit_metrics
                          ?.new_customers_created_count)
                      }
                      ThirdValue={
                        formatNumber(dashobardData?.visit_metrics
                          ?.new_customers_created_rejected_count)
                      }
                      // todo
                      firstName="Aprroved"
                      secondName="Under Approval"
                      thirdName="Rejected"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="grid grid-cols-1 lg:grid-cols-3  mb-6 gap-4">
            <div className="bg-white  lg:col-span-1 flex gap-4 flex-col"></div>
            <div className="bg-white   lg:col-span-1 flex flex-col gap-4"></div>
          </div> */}
          </div>
        </div>
        {open && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end sm:items-start items-center z-50">
            <div className="bg-white shadow-lg p-6 sm:p-12 w-full max-w-md h-screen">
              <div className="text-lg font-semibold text-gray-800">Filters</div>
              <div className="mt-4 space-y-4">
                <div >
                  <label className="block text-gray-700 mb-1">
                    Employee Name{" "}
                  </label>
                  <select
                    className="w-full border border-gray-300 rounded p-2"
                    value={emp}
                    onChange={(e) => setEmployee(e.target.value)}
                  >
                    <option value="">Select employee</option>
                    {leaderData &&
                    leaderData?.filters?.employees?.length > 0 ? (
                      leaderData?.filters?.employees.map((employee: any) => (
                        <option key={employee.emp_id} value={employee.emp_id}>
                          {employee.name_eng}
                        </option>
                      ))
                    ) : (
                      <option >No employee available</option>
                    )}
                  </select>
                </div>
                <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                  <div className="w-full sm:w-1/2">
                    <label className="block text-gray-700 mb-1">
                      Sales Office
                    </label>
                    <select
                      className="w-full border border-gray-300 rounded p-2"
                      value={salesOffice}
                      onChange={handleSalesOffice}
                    >
                      <option value={""}>Select sales office</option>
                      {leaderData &&
                      leaderData?.filters?.sales_offices?.length > 0 ? (
                        leaderData?.filters?.sales_offices.map(
                          (office: any) => (
                            <option key={office.name} value={office.name}>
                              {office.name}
                            </option>
                          )
                        )
                      ) : (
                        <option>No office available</option>
                      )}
                    </select>
                  </div>

                  <div className="w-full sm:w-1/2">
                    <label className="block text-gray-700 mb-1">Region</label>
                    <select
                      className="w-full border border-gray-300 rounded p-2"
                      value={salesRegion}
                      onChange={(e) => setSelectedSalesRegion(e.target.value)}
                    >
                      <option value={""}>Select region</option>
                      {regionList?.length > 0 ? (
                        regionList?.map((office: any) => (
                          <option key={office} value={office}>
                            {office}
                          </option>
                        ))
                      ) : (
                        <option>No region available</option>
                      )}
                    </select>
                  </div>
                </div>

                <div className="date-range-picker space-y-2">
                  <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                    <div>
                      <label>Start Date:</label>
                      <input
                        type="date"
                        className="border border-gray-300 rounded p-1"
                        value={startDate}
                        onChange={handleStartDateChange}
                      />
                    </div>
                    <div>
                      <label>End Date:</label>
                      <input
                        type="date"
                        className="border border-gray-300 rounded p-1"
                        value={endDate}
                        onChange={handleEndDateChange}
                      />
                    </div>
                  </div>
                  <div>
                    <TimeFrameSelector
                      customClass={"w-full border border-gray-300 rounded p-2"}
                      setSelectTimeFrame={setSelectTimeFrame}
                      timeFrame={timeFrame}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                </div>
                <div></div>
              </div>

              <div className="flex justify-between mt-4">
                <button
                  onClick={handleClose}
                  className="px-4 py-2 text-white bg-gray-400 rounded transition duration-200 hover:bg-gray-500"
                >
                  Cancel
                </button>
                <button
                  onClick={handleApply}
                  className="px-4 py-2 text-white bg-blue-500 rounded transition duration-200 hover:bg-blue-600"
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="lg:max-w-[35%]   w-full rounded ">
          <h2 className="text-base font-bold p-4 text-white bg-purple ">
            Leaderboard
          </h2>
          <div
            ref={scrollContainerRef}
            id="scorll-container"
            className="overflow-x-hidden overflow-scroll h-[645px] lg:max-h-[calc(100vh-150px)] scrollbar scrollbar-thin "
          >
            <LeaderTable
              leaderData={leaderDataList}
              loader={isLoading}
              getIndex={getIndex}
            />
          </div>

          {/* <PaginationComponent
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalCount={leaderData?.total}
            itemsPerPage={leaderData?.size}
          /> */}
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default Dashboard;
