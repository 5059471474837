import axios from "./config";
import Cookies from "js-cookie";
import { getHeader ,getUserInfo } from "../utility/index";
interface VerfiyMail {
  email: string;
}
interface LoginData {
  email: string;
  password: string;
  mfa_code: string
}

interface ApiResponse {
  data: any; 
}

const verifyMail = async (data: VerfiyMail): Promise<ApiResponse> => {
  const response = await axios.post<ApiResponse>("/auth/verify-email", data);
  return response; 
};

const loginUser = async (data: LoginData): Promise<ApiResponse> => {
  const response = await axios.post<ApiResponse>("/auth/login", data);
  return response.data; 
};


const refreshToken = async (): Promise<ApiResponse> => {
  const header = {
    Authorization:  Cookies.get('refresh_token'),
  };
  const response = await axios.post<ApiResponse>("/auth/token/refresh",null,{headers:header});
  return response; 
};


const resetPassLink = async (data:VerfiyMail): Promise<ApiResponse> => {
  const response = await axios.post<ApiResponse>("/auth/send-password-reset-mail", data);
  return response.data; 
};

const resetPass = async (token:any,pass:any): Promise<ApiResponse> => {
  const header = {
    Authorization: token,
  };
  const response = await axios.post<ApiResponse>("/auth/reset-password", {password1:pass ,password2:pass}, {
     headers:header   
  });
  return response; 
};

const loginOtpVerify = async (data: LoginData): Promise<ApiResponse> => {
  const header = {
    Authorization: Cookies.get("token"),
  };

  const response = await axios.post<ApiResponse>("/auth/login", data ,{
    headers:header
  });
  return response; 
};

const getQrCode = async (token:any): Promise<ApiResponse> => {
  const header = {
    Authorization:  token,
  };
  let data=''
  const response = await axios.post<ApiResponse>("/auth/mfa/setup",data,{
    headers:header
  });
  return response; 
};


const googleAuthVerify = async (token: string ,mfa:string): Promise<ApiResponse> => {
  const header = {
    Authorization: token,
  };

  const response = await axios.post<ApiResponse>("/auth/mfa/verify", {mfa_code:mfa} ,{
    headers:header
  });
  return response; 
};


export default loginUser;
export {loginOtpVerify ,verifyMail ,resetPass ,resetPassLink ,getQrCode ,googleAuthVerify ,refreshToken}
