import React, { useEffect, useState, useRef } from "react";
import {
  Map,
  Marker,
  APIProvider,
  useMap,
  InfoWindow,
} from "@vis.gl/react-google-maps";

interface GoogleMapProps {
  showMarkers?: boolean;
  showDirections?: boolean;
  showTerritories?: boolean;
  markersData?: {
    position: { lat: number; lng: number };
    label: string;
    status: string;
  }[];
  directionsData?: any;
  territoriesData?: any;
  setSelectedMarker?: any;
  selectedMarker?: any;
  actualData?: any;
  selectedData?: any;
  plannedData?: any;
  selectedRoute?: any;
  handleRouteChange?: any;
  handleMarkerClick?: any;
  markarData?: any;
  showNotificationInfo?: any;
  customerLoaction?: any;
  storeLoaction?: any;
  handleCloseInfoWindow?: any;
}

interface Coordinate {
  lat: number;
  lng: number;
}
interface PolygonData {
  id: number;
  coords: Coordinate[];
  fillColor: string;
}

const GoogleMap: React.FC<GoogleMapProps> = ({
  showMarkers,
  showDirections,
  showTerritories,
  markersData,
  directionsData,
  territoriesData,
  setSelectedMarker,
  selectedMarker,
  plannedData,
  actualData,
  selectedData,
  selectedRoute,
  handleRouteChange,
  handleMarkerClick,
  markarData,
  showNotificationInfo,
  customerLoaction,
  storeLoaction,
  handleCloseInfoWindow,
}) => {
  const saudiCenter = { lat: 24.774265, lng: 46.738586 };
  const saudiBounds = {
    north: 32.0,
    south: 16.0,
    east: 60.0,
    west: 34.0,
  };
  const mapRef = useRef<google.maps.Map | null>(null);

  let newDirectionsRendererActual: any = "";
  let newDirectionsRendererPlanned: any = "";
  let newDirectionsRendererPlannedRoute: any = "";

  const DirectionsBoth: React.FC = () => {
    const map = useMap();
    const [directionsRendererActual, setDirectionsRendererActual] =
      useState<any>(null);
    const [directionsRendererPlanned, setDirectionsRendererPlanned] =
      useState<any>(null);

    useEffect(() => {
      if (map && window.google) {
        if (!showDirections) {
          if (directionsRendererActual) {
            directionsRendererActual.setMap(null);
          }
          if (directionsRendererPlanned) {
            directionsRendererPlanned.setMap(null);
          }
          return;
        }

        // if (selectedData) {
        //   const requestSelected = {
        //     origin: actualData.origin,
        //     destination: { lat: 24.772, lng: 46.7401 },
        //     // waypoints: directionsData.waypoints,
        //     travelMode: window.google.maps.TravelMode.DRIVING,
        //   };

        //   const directionsServiceSelected =
        //     new window.google.maps.DirectionsService();
        //   directionsServiceSelected.route(requestSelected, (result, status) => {
        //     if (status === window.google.maps.DirectionsStatus.OK) {
        //       newDirectionsRendererRequested.setDirections(result);
        //     } else {
        //       console.error("Planned route request failed due to " + status);
        //     }
        //   });

        //   // Requested selected Route Renderer
        //   const newDirectionsRendererRequested =
        //     new window.google.maps.DirectionsRenderer({
        //       polylineOptions: {
        //         strokeColor: "red",
        //         strokeOpacity: 1.0,
        //         strokeWeight: 4,
        //       },
        //     });
        //   newDirectionsRendererRequested.setMap(map);
        //   setDirectionsRendererRequested(newDirectionsRendererRequested);
        //   return;
        // }

        // Actual Route Renderer

        newDirectionsRendererActual = new window.google.maps.DirectionsRenderer(
          {
            polylineOptions: {
              strokeColor: "blue",
              strokeOpacity: 1.0,
              strokeWeight: 4,
            },
          }
        );
        newDirectionsRendererActual.setMap(map);
        setDirectionsRendererActual(newDirectionsRendererActual);

        // Request actual route
        const requestActual = {
          origin: actualData?.origin,
          destination: actualData?.destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
          // waypoints: [{ location: { lat: 24.7743, lng: 46.7386 } }],
        };

        const directionsServiceActual =
          new window.google.maps.DirectionsService();
        directionsServiceActual.route(requestActual, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            newDirectionsRendererActual.setDirections(result);
          } else {
            console.error("Actual route request failed due to " + status);
          }
        });

        // Planned Route Renderer

        newDirectionsRendererPlanned =
          new window.google.maps.DirectionsRenderer({
            polylineOptions: {
              strokeColor: "black",
              strokeOpacity: 1.0,
              strokeWeight: 4,
            },
          });
        newDirectionsRendererPlanned.setMap(map);
        setDirectionsRendererPlanned(newDirectionsRendererPlanned);

        const requestPlanned = {
          origin: plannedData?.origin,
          destination: plannedData?.destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
          waypoints: plannedData?.waypoints,
        };

        const directionsServicePlanned =
          new window.google.maps.DirectionsService();
        directionsServicePlanned.route(requestPlanned, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            newDirectionsRendererPlanned.setDirections(result);
          } else {
            console.error("Actual route request failed due to " + status);
          }
        });

        return () => {
          if (newDirectionsRendererPlanned) {
            newDirectionsRendererPlanned.setMap(null);
          }
          if (newDirectionsRendererActual) {
            newDirectionsRendererActual.setMap(null);
          }
        };
      }
    }, [map, actualData, plannedData, showDirections]);

    return null;
  };

  const DirectionsActual: React.FC = () => {
    const map = useMap();
    const [directionsRendererActual, setDirectionsRendererActual] =
      useState<any>(null);
    const [directionsRendererPlanned, setDirectionsRendererPlanned] =
      useState<any>(null);

    useEffect(() => {
      if (map && window.google) {
        // Clear previous directions when not showing
        if (!showDirections) {
          if (directionsRendererActual) {
            directionsRendererActual.setMap(null);
          }
          if (directionsRendererPlanned) {
            directionsRendererPlanned.setMap(null);
          }
          return;
        }

        // Request actual route
        // Request actual route
        // Request actual route
        newDirectionsRendererActual = new window.google.maps.DirectionsRenderer(
          {
            polylineOptions: {
              strokeColor: "blue",
              strokeOpacity: 1.0,
              strokeWeight: 4,
            },
          }
        );
        newDirectionsRendererActual.setMap(map);
        setDirectionsRendererActual(newDirectionsRendererActual);

        console.log(actualData, "actual");
        const requestActual = {
          origin: actualData?.origin,
          destination: actualData?.destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
          // waypoints: [{ location: { lat: 24.7743, lng: 46.7386 } }],
        };

        const directionsServiceActual =
          new window.google.maps.DirectionsService();
        directionsServiceActual.route(requestActual, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            newDirectionsRendererActual.setDirections(result);
          } else {
            console.error("Actual route request failed due to " + status);
          }
        });

        // Request actual route end
        // Request actual route end
        // Request actual route end

        newDirectionsRendererPlanned =
          new window.google.maps.DirectionsRenderer({
            polylineOptions: {
              strokeColor: "black",
              strokeOpacity: 1.0,
              strokeWeight: 4,
            },
          });
        newDirectionsRendererPlanned.setMap(map);
        setDirectionsRendererPlanned(newDirectionsRendererPlanned);

        const requestPlanned = {
          origin: plannedData?.origin,
          destination: plannedData?.destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
          waypoints: plannedData?.waypoints,
        };

        const directionsServicePlanned =
          new window.google.maps.DirectionsService();
        directionsServicePlanned.route(requestPlanned, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            newDirectionsRendererPlanned.setDirections(result);
          } else {
            console.error("Actual route request failed due to " + status);
          }
        });

        return () => {
          if (newDirectionsRendererActual) {
            newDirectionsRendererActual.setMap(null);
          }
          if (newDirectionsRendererPlanned) {
            newDirectionsRendererPlanned.setMap(null);
          }
        };
      }
    }, [map, actualData, plannedData, showDirections]);

    return null;
  };

  const DirectionsPlanned: React.FC = () => {
    const map = useMap();
    const [directionsRendererActual, setDirectionsRendererActual] =
      useState<any>(null);

    useEffect(() => {
      if (map && window.google) {
        if (!showDirections) {
          if (directionsRendererActual) {
            directionsRendererActual.setMap(null);
          }
          return;
        }

        // Planned Route Renderer

        newDirectionsRendererActual = new window.google.maps.DirectionsRenderer(
          {
            polylineOptions: {
              strokeColor: "black",
              strokeOpacity: 1.0,
              strokeWeight: 4,
            },
          }
        );
        newDirectionsRendererActual.setMap(map);
        setDirectionsRendererActual(newDirectionsRendererActual);

        // Request actual route
        const requestActual = {
          origin: plannedData?.origin,
          destination: plannedData?.destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
          waypoints: plannedData?.waypoints,
        };

        const directionsServiceActual =
          new window.google.maps.DirectionsService();
        directionsServiceActual.route(requestActual, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            newDirectionsRendererActual.setDirections(result);
          } else {
            console.error("Actual route request failed due to " + status);
          }
        });

        return () => {
          if (newDirectionsRendererActual) {
            newDirectionsRendererActual.setMap(null);
          }
          if (newDirectionsRendererPlannedRoute) {
            newDirectionsRendererPlannedRoute.setMap(null);
          }
        };
      }
    }, [map, plannedData, showDirections]);

    return null;
  };

  const MultiplePolygons: React.FC<{ polygonsData: PolygonData[] }> = ({
    polygonsData,
  }) => {
    const map = useMap();
    const [selectedPolygonId, setSelectedPolygonId] = useState<number | null>(
      null
    );

    useEffect(() => {
      if (map && window.google) {
        map.data.forEach((feature) => {
          map.data.remove(feature);
        });

        polygonsData.forEach((polygonData) => {
          const polygon = new window.google.maps.Polygon({
            paths: polygonData.coords,
            strokeColor: polygonData.fillColor,
            // strokeColor: polygonData.strokeColor,
            strokeOpacity: 0,
            strokeWeight: 2,
            fillColor: polygonData.fillColor,
            fillOpacity: 0.1,
          });

          polygon.setMap(map);

          window.google.maps.event.addListener(polygon, "click", () => {
            setSelectedPolygonId(polygonData.id); // Track the selected polygon id
          });
        });
      }
    }, [map, polygonsData]);

    useEffect(() => {
      if (map && selectedPolygonId !== null) {
        // Highlight the selected polygon without changing opacity
        const selectedPolygon = polygonsData.find(
          (p) => p.id === selectedPolygonId
        );
        if (selectedPolygon) {
          const highlightPolygon = new window.google.maps.Polygon({
            paths: selectedPolygon.coords,
            strokeColor: selectedPolygon.fillColor, // Change color or style to highlight
            strokeOpacity: 1.0,
            strokeWeight: 3,
            fillColor: selectedPolygon.fillColor,
            fillOpacity: 0.1,
          });

          highlightPolygon.setMap(map);
          return () => {
            highlightPolygon.setMap(null);
          };
        }
      }
    }, [map, selectedPolygonId, polygonsData]);

    return null;
  };

  const getFillColor = (status: string) => {
    switch (status) {
      case "idle":
        return "#FF4D68";
      case "online":
        return "#00C48C";
      case "enroute":
        return "purple";
    }
  };

  const map = useMap();

  const handleMarkerClickForInfo = (customer:any) => {
    handleMarkerClick(customer)
    if (map) {
      map.setCenter(customer?.position); // Center the map on the marker
      map.setZoom(15); // Zoom to the marker
    }
  };

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY || ""}>
      <Map
        style={{ width: "100%", height: "100%" }}
        defaultCenter={saudiCenter}
        defaultZoom={0}
        gestureHandling={"greedy"}
        disableDefaultUI={true}
        minZoom={5}
        maxZoom={15}
        zoomControl={true}
        restriction={{
          latLngBounds: saudiBounds,
          strictBounds: true,
        }}
      >
        {showMarkers &&
          markersData?.map((marker, index) => (
            <Marker
              key={index}
              position={marker?.position}
              icon={{
                path: "M12 2a10 10 0 1 1 0 20a10 10 0 0 1 0-20z",
                fillColor: getFillColor(marker.status),
                fillOpacity: 1,
                scale: 1,
                strokeColor: "white",
                strokeWeight: 2,
              }}
              onClick={() => handleMarkerClick(marker)}
            />
          ))}

        {selectedMarker && (
          <InfoWindow
            position={selectedMarker?.position}
            onCloseClick={handleCloseInfoWindow}
            className="hidden md:block"
          >
            <div className="pt-2">
              {showNotificationInfo && <p>Name : {markarData?.name_eng}</p>}
              <p>
                Total traveling till now :{markarData?.total_traveling_till_now}{" "}
                Km
              </p>
              <p>Total visits till now : {markarData?.total_visits}</p>
              <p>Successful visits : {markarData?.successful_visits}</p>
              <p>Failed Visits : {markarData?.failed_visits} </p>
              <p>Productive Visit : {markarData?.productive_visits}</p>
            </div>
          </InfoWindow>
        )}

        {showDirections && selectedRoute === "" && <DirectionsBoth />}
        {showDirections && selectedRoute === "actual" && <DirectionsActual />}
        {showDirections && selectedRoute === "planned" && <DirectionsPlanned />}
        {/* {showDirections && <Directions  />} */}

        {showTerritories && <MultiplePolygons polygonsData={territoriesData} />}
        {showDirections && (
          <>
            <div className="p-2 pl-4 absolute bottom-[20px] left-[2%] z-index-[9999] bg-white w-[300px]  flex items-center gap-3">
              <div className="flex gap-2 items-center">
                <div className="bg-black w-[10px] h-[10px] rounded-full"></div>
                Planned route
              </div>
              <div className="flex gap-2 items-center">
                <div className="bg-cublue w-[10px] h-[10px] rounded-full"></div>
                Actual route
              </div>
            </div>
          </>
        )}
        {(showMarkers ||
          showNotificationInfo) && (
            <>
              <div className="p-2 pl-4 absolute bottom-[20px] left-[2%] z-index-[9999] bg-white w-auto  flex items-center gap-3">
                {showNotificationInfo &&  <div className="flex gap-2 items-center">
                  <div className="bg-black w-[10px] h-[10px] rounded-full"></div>
                  Store location
                </div>}
               
                <div className="flex gap-2 items-center">
                  <div className="bg-purple w-[10px] h-[10px] rounded-full"></div>
                  Enroute
                </div>
                <div className="flex gap-2 items-center">
                  <div className="bg-cugreen w-[10px] h-[10px] rounded-full"></div>
                  At location
                </div>
                <div className="flex gap-2 items-center">
                  <div className="bg-customorange w-[10px] h-[10px] rounded-full"></div>
                  Idle
                </div>
              </div>
            </>
          )}

        {showDirections && (
          <div className="absolute top-5 left-5 bg-opacity-90 bg-white p-4  flex flex-col gap-2">
            <div>
              Compare route
              <div>
                <label>
                  <input
                    name="route"
                    checked={selectedRoute === "planned"}
                    onChange={handleRouteChange}
                    value="planned"
                    type="radio"
                  />{" "}
                  Planned Route
                </label>
              </div>
              <div>
                <label>
                  {" "}
                  <input
                    name="route"
                    checked={selectedRoute === "actual"}
                    onChange={handleRouteChange}
                    value="actual"
                    type="radio"
                  />{" "}
                  Actual Route
                </label>
              </div>
            </div>
            <div>
              Breaks
              <div>
                <label>
                  {" "}
                  <input
                    name="route"
                    checked={selectedRoute === "break"}
                    onChange={handleRouteChange}
                    value="break"
                    type="radio"
                  />{" "}
                  Stops
                </label>
              </div>
            </div>
          </div>
        )}

        {showNotificationInfo && (
          <>
            <Marker
              position={customerLoaction.position}
              icon={{
                path: "M12 2a10 10 0 1 1 0 20a10 10 0 0 1 0-20z",
                fillColor: getFillColor("idle"),
                fillOpacity: 1,
                scale: 1,
                strokeColor: "white",
                strokeWeight: 2,
              }}
              onClick={() => handleMarkerClickForInfo(customerLoaction)}
            />
            <Marker
              position={storeLoaction.position}
              icon={{
                path: "M12 2C8.13 2 5 5.13 5 9c0 3.59 3.4 8.34 6.32 11.52c.38.43.99.43 1.36 0C15.6 17.34 19 12.59 19 9c0-3.87-3.13-7-7-7zM12 11.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5z",
                fillColor: "black",
                fillOpacity: 1,
                scale: 2,
                strokeColor: "white",
                strokeWeight: 1,
              }}
              // onClick={() => handleMarkerClick(storeLoaction.id)}
            />
          </>
        )}
      </Map>
    </APIProvider>
  );
};

export default GoogleMap;
