import React from 'react';

interface MetricBarProps {
  text?: string;
  count: string;
  maxCount: number;
  color: string;
}

const MetricBar: React.FC<MetricBarProps> = ({ text, count, maxCount, color }) => {
  const widthPercentage = (Number(count) / maxCount) * 100;

  return (
    <li className="flex justify-between items-center border-b-2 pb-2">
      <span className="text-[13px] font-normal text-[#4A4A4A]">{text}</span>
      <div className="flex items-center space-x-2 w-[60%] justify-end">
        <div
          className="h-2 rounded-full"
          style={{
            width: `${widthPercentage}%`,
            backgroundColor: color,
          }}
        ></div>
        <span
          className={`font-bold text-lg border rounded-md px-2 py-1`}
          style={{
            color: color,
            borderColor: color,
          }}
        >
          {count}
        </span>
      </div>
    </li>
  );
};

export default MetricBar;
