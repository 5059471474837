import React from "react";

interface WorkStatsCardProps {
  workingDays: string;
  title: string;
}

const WorkStatsCard: React.FC<WorkStatsCardProps> = ({
  workingDays,
  title,
}) => {
  return (
    <div className="border border-gray-300  md:max-w-none p-4 w-full h-auto">
      <p className="text-14 text-grey ">{title}</p>
      <p className="text-28 text-purple font-bold">{workingDays}</p>
    </div>
  );
};

export default WorkStatsCard;
