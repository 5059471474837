import React from "react";
import GaugeChart from "react-gauge-chart";

interface GaugeChartProps {
  target?: number | any;
  served?: number | any;
  pending?: number | string;
  value?: number | string;
}

const CustomGaugeChart: React.FC<GaugeChartProps> = ({
  served,
  pending,
  target,
  value,
}) => {
  value = ((Number(served) / Number(target)) * 100) / 100;
  return (
    <>
      <GaugeChart
        id="gauge-chart"
        nrOfLevels={50}
        percent={value ? value :0}
        arcPadding={0.02}
        cornerRadius={3}
        needleColor="#345243"
        needleBaseColor="#464A4F"
        animate={true}
        hideText={true}
        colors={["#00C48C", "#FF4D68"]}
      />
      <div className="px-10">
        <span>0</span> <span style={{ float: "right" }}>{target}</span>
      </div>
    </>
  );
};

export default CustomGaugeChart;
