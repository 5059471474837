
import axios ,{instanceGps} from "./config";
import {  getHeader ,getUserInfo } from "../utility/index";
import Cookies from "js-cookie";

interface ApiResponse {
    data: any; 
  }

const approveRequest = async (id:Number,data:any): Promise<ApiResponse> => {
    const response = await axios.post<ApiResponse>(`/supervisors/${id}/action`,data, {
       headers:getHeader()   
    });
    return response; 
};
export default approveRequest;
