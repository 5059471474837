import React, { useState, useEffect } from "react";
import Header from "../../layout/header";
import bannerImage from "../../assets/landingPage-banner.svg";
import MyTeam from "../../assets/landingPage-icon/MyTeam.svg";
import MyTeamHover from "../../assets/landingPage-icon/MyTeams-hover.svg";
import MyTeamClick from "../../assets/landingPage-icon/MyTeam-click.svg";
import Customers from "../../assets/landingPage-icon/Customers.svg";
import CustomersHover from "../../assets/landingPage-icon/Customers-hover.svg";
import CustomersClick from "../../assets/landingPage-icon/Customers-click.svg";
import Planogram from "../../assets/landingPage-icon/Planogram.svg";
import PlanogramHover from "../../assets/landingPage-icon/Planogram-hover.svg";
import PlanogramClick from "../../assets/landingPage-icon/Planogram-click.svg";
import Territory from "../../assets/landingPage-icon/Territory.svg";
import TerritoryHover from "../../assets/landingPage-icon/Territory-hover.svg";
import TerritoryClick from "../../assets/landingPage-icon/Territory-click.svg";
import Print from "../../assets/landingPage-icon/Print.svg";
import PrintHover from "../../assets/landingPage-icon/Print-hover.svg";
import Finance from "../../assets/landingPage-icon/Finanace.svg";
import FinanceHovar from "../../assets/landingPage-icon/Finance-hover.svg";
import Admin from "../../assets/landingPage-icon/Admin.svg";
import AdminHover from "../../assets/landingPage-icon/Admin-hover.svg";
import AdminClick from "../../assets/landingPage-icon/Admin-click.svg";
import URL_MAPPING from "../../routes/constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { getUserInfo } from "../../utility";
import { NotificationProvider } from "../../notification";

const Index: React.FC = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState<number | null>(null);
  const [clickedButton, setClickedButton] = useState<number | null>(null);
  const { t } = useTranslation();
  const buttonData = [
    {
      id: 1,
      label: t("Landing.myTeam"),
      icons: { default: MyTeam, hover: MyTeamHover, click: MyTeamClick },
      url: URL_MAPPING.ANALYTICS,
      disabled: false,
      name: "My Team",
    },
    {
      id: 2,
      label: t("Landing.customers"),
      icons: {
        default: Customers,
        hover: CustomersHover,
        click: CustomersClick,
      },
      url: URL_MAPPING.MASTERLIST,
      disabled: false,
      name: "Customers",
    },
    {
      id: 3,
      label: t("Landing.planogram"),
      icons: {
        default: Planogram,
        hover: PlanogramHover,
        click: PlanogramClick,
      },
      url:URL_MAPPING.PLANOGRAM,
      disabled: false,
      name: "Planogram",
    },
    // {
    //   id: 4,
    //   label: t("Landing.admin"),
    //   icons: { default: Admin, hover: AdminHover, click: AdminClick },
    //   url: "test",
    //   disabled: false,
    //   name: "Admin",
    // },
    {
      id: 5,
      label: t("Landing.territory"),
      icons: {
        default: Territory,
        hover: TerritoryHover,
        click: TerritoryClick,
      },
      url: URL_MAPPING.TERRITORIES,
      disabled: true,
      name: "Territory",
    },

    {
      id: 6,
      label: t("Landing.print"),
      icons: { default: Print, hover: PrintHover, click: AdminClick },
      url: URL_MAPPING.CUSTOMERLIST,
      disabled: true,
      name: "Print",
    },
    {
      id: 7,
      label: t("Landing.finance"),
      icons: { default: Finance, hover: FinanceHovar, click: AdminClick },
      url: URL_MAPPING.CUSTOMERLIST,
      disabled: true,
      name: "Finance",
    },
  ];

  const handleButtonClick = (button: any) => {
    localStorage.setItem("section", button.name);
    navigate(button.url);
  };

  const images = [bannerImage, bannerImage];
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  const interval = 3000;
  useEffect(() => {
    const slideInterval = setInterval(goToNextSlide, interval);
    return () => clearInterval(slideInterval); // Clear interval on component unmount
  }, [currentIndex, interval]);

  return (
    <div>
      <div className="w-full  fixed z-50">
        <Header hide={true} />
      </div>
      <div className="flex flex-col items-center p-4 pt-[54px]">
        <div className=" mb-6 overflow-hidden rounded-t-xl w-full ">
          {/* <img
            src={bannerImage}
            alt="Banner"
            className="w-screen h-auto object-contain"
          /> */}
          <div id="carousel" className="relative w-full">
            {/* Carousel Wrapper */}
            <div className="relative w-full h-56 overflow-hidden rounded-lg md:h-96">
              {images?.map((image, index) => (
                <div
                  key={index}
                  className={`${
                    index === currentIndex ? "block" : "hidden"
                  } duration-700 ease-in-out`}
                >
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                  />
                </div>
              ))}
            </div>

            {/* Indicators */}
            <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3">
              {images.map((_, index) => (
                <button
                  key={index}
                  className={`w-3 h-3 rounded-full ${
                    index === currentIndex ? "bg-white" : "bg-gray-300"
                  }`}
                  aria-label={`Slide ${index + 1}`}
                  onClick={() => setCurrentIndex(index)}
                ></button>
              ))}
            </div>

            {/* Previous Button */}
            <button
              type="button"
              className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
              onClick={goToPreviousSlide}
            >
              <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50">
                {/* Add SVG for left arrow */}
                <svg
                  className="w-4 h-4 text-white"
                  aria-hidden="true"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    d="M5 1L1 5l4 4"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="sr-only">Previous</span>
              </span>
            </button>

            {/* Next Button */}
            <button
              type="button"
              className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
              onClick={goToNextSlide}
            >
              <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50">
                {/* Add SVG for right arrow */}
                <svg
                  className="w-4 h-4 text-white"
                  aria-hidden="true"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    d="M1 1l4 4-4 4"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="sr-only">Next</span>
              </span>
            </button>
          </div>
        </div>
        <hr className="w-[calc(100vw-50px)] border-t-2 border-gray-300  mb-6" />

        <div className="grid grid-cols-2 md:grid-cols-6 gap-4 w-full max-w-7xl pb-[100px]">
          {buttonData?.map((button, index) => {
            let iconSrc = button.icons.default;
            if (clickedButton === index) iconSrc = button.icons.click;
            else if (activeButton === index) iconSrc = button.icons.hover;

            return (
              <button
                key={button.id}
                className={`group flex flex-col items-center cursor-pointer mt-12 justify-center  ${
                  button.disabled
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:text-purple transition-colors"
                }`}
                onMouseEnter={() => setActiveButton(index)}
                onMouseLeave={() => setActiveButton(null)}
                onClick={() => handleButtonClick(button)}
                disabled={button.disabled}
              >
                <img
                  src={iconSrc}
                  alt={button.label}
                  className={`${
                    button.disabled
                      ? ""
                      : "transition-transform duration-100 ease-in-out hover:scale-110"
                  } w-32 h-32 mb-2 transform `}
                />
                <div className="transition-colors group:hover:text-purple">
                  <b>{button.label}</b>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Index;
