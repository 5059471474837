import React, { useState, useRef, useEffect } from "react";
import Button from "../../components/LoaderButton";
import logo from "../../assets/logo.svg";
import Back from "../../assets/back-arrow.svg";
import { useTranslation } from "react-i18next";

interface OtpVerificationProps {
  loader: boolean;
  handleSubmit: any;
  handleBack?: any;
  error?: string;
}

const OtpVerification: React.FC<OtpVerificationProps> = ({
  loader,
  handleSubmit,
  handleBack,
  error,
}) => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [timeLeft, setTimeLeft] = useState(120);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer); // Cleanup the timer on unmount or re-render
    }
  }, [timeLeft]);

  const handleChange = (e: any, index: number) => {
    const { value } = e.target;
    if (value === "") {
      const newOtp = [...otp];
      newOtp[index] = ""; // Clear current input

      // Focus the previous input if available
      if (index > 0 && inputRefs.current[index - 1]) {
        inputRefs.current[index - 1]?.focus();
      }

      setOtp(newOtp);
    } else if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp?.length - 1 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
    if (e.key === 'Enter') {
      handleSubmit(otp);  
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <>
      {" "}
      <div className=" relative w-full md:w-1/2 flex flex-col justify-center items-center bg-white md:px-20  sm:p-10 p-4 ">
        <div className="mb-8 ">
          <img
            src={Back}
            alt="Logo"
            className="absolute left-8 cursor-pointer mt-[-90px] md:mt-0"
            onClick={handleBack}
          />
          <img src={logo} alt="Logo" className="h-18" />
        </div>

        <div className="mb-6 px-4 py-4 text-center">
          <h2 className="text-2xl font-bold text-purple mb-1">
            {t("SignUp.enterYourVerficationCode")}
          </h2>
          <p className="text-grey max-w-[335px] text-base">
            {t("SignUp.toIncreaseSecurity")}
          </p>
        </div>
        <div className="flex w-full justify-between gap-2">
          {otp.map((digit, index) => (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              type="number"
              maxLength={1}
              value={digit}
              className="w-full max-w-[64px] h-[64px] bg-lightgrey p-3 text-center text-28 rounded"
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onFocus={(e) => e.target.select()}
            />
          ))}
        </div>
        <div className="mb-4 w-full text-red-600 text-sm text-center mt-6">
          {error}
        </div>
        <div className="mt-6 w-full">
          <Button
            text={t("SignUp.verify")}
            onClick={() => handleSubmit(otp)}
            isLoading={loader}
            disabled={loader}
          />
        </div>
        {/* <div className="mt-3 text-grey text-13">
          {timeLeft === 0 ? (
            <p className=" text-grey text-13 cursor-pointer">
              {t("SignUp.requestCodeAgain")}
            </p>
          ) : (
            <>
              <p className=" text-grey text-13 ">
                {t("SignUp.requestCodeAgain")}
                <b className="text-black">{formatTime(timeLeft)}</b>
              </p>
            </>
          )}
        </div> */}
      </div>
    </>
  );
};

export default OtpVerification;
