// ProgressBar.tsx
import React from "react";

interface ProgressBarProps {
  active: number;
  inactive: number;
  onLeave: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  active,
  inactive,
  onLeave,
}) => {
  // Calculate total and proportions
  const total = active + inactive + onLeave;
  const activeWidth = (active / total) * 100;
  const inactiveWidth = (inactive / total) * 100;
  const onLeaveWidth = (onLeave / total) * 100;

  return (
    <div className="w-full flex gap-6 flex-col px-4 pt-3">
      <div className="flex flex-col gap-2">
        <div
          className="bg-cugreen text-white rounded text-center"
          style={{ width: `${activeWidth}%`, height: "30px" }}
        ></div>

        <div
          className="bg-customorange text-white rounded text-center"
          style={{ width: `${inactiveWidth}%`, height: "30px" }}
        ></div>

        <div
          className="bg-culightgrey text-white rounded text-center"
          style={{ width: `${onLeaveWidth}%`, height: "30px" }}
        ></div>
      </div>

      <div>
        <div className="flex justify-between">
          <div className="flex gap-2 items-center">
            <div className="bg-cugreen w-[15px] h-[15px] rounded-full"></div>
            Actvie
          </div>
          <div className="fold-bold text-20">{active}</div>
        </div>
        <div className="flex justify-between">
          <div className="flex gap-2 items-center">
            <div className="bg-customorange w-[15px] h-[15px] rounded-full"></div>
            Inactive
          </div>
          <div className="fold-bold text-20">{inactive}</div>
        </div>
        <div className="flex justify-between">
          <div className="flex gap-2 items-center">
            <div className="bg-culightgrey w-[15px] h-[15px] rounded-full"></div>
            On Leave
          </div>
          <div className="fold-bold text-20">{onLeave}</div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
