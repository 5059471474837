import React, { useEffect, useState } from "react";
import DashboardPage from "../../layout";
import { visitReportList } from "../../api/employee";
import PaginationComponent from "../../components/Pagination";
import ActionItem from "../../assets/actionItem.svg";
import MenuDots from "../../assets/menuDots.svg";
import Loader from "../../components/Loader";
import Export from "../../assets/icons/Export.svg";
import Sort from "../../assets/icons/Sort.svg";
import Filter from "../../assets/icons/Filter.svg";
import { formatCurrentStartDate, formatCurrentEndDate } from "../../utility";
import TimeFrameSelector from "../../components/TimeFrameSelector";
import { useNavigate } from "react-router-dom";
import URL_MAPPING from "../../routes/constants";

const VisitReport: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const employeesPerPage = 15;
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [employees, setEmployeesData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedEmpId, setSelectedEmpId] = useState<any>("");
  const [selectedSalesOffice, setSelectedSalesOffice] = useState<any>("");
  const [timeFrame, setSelectTimeFrame] = useState<any>("Today");
  const [MobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [status, setStatus] = useState<any>("");
  const navigate = useNavigate();

  const handleFilterClick = () => {
    setOpen(true);
  };

  const handleSearch = (e: any) => {
    setSearchQuery(e);
    const data = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      search: e,
    };
    const params = {
      page: currentPage,
    };
    getEmployeesData(data, params);
  };

  const handleSort = () => {
    console.log("Sort button clicked");
  };

  const handleClose = () => setOpen(false);

  const handleExport = async () => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      export: true,
    };

    if (searchQuery) {
      data["search"] = searchQuery;
    }

    if (status) {
      data["status"] = status;
    }

    if (selectedEmpId) {
      data["subordinate_ids"] = [selectedEmpId];
    }

    if (selectedSalesOffice) {
      data["sales_office_id"] = selectedSalesOffice;
    }

    const params = {};
    exportActiviyData(data, params);
  };

  const exportActiviyData = async (data: any, params: any) => {
    await visitReportList(params, data)
      .then((res) => {
        console.log(res, "response");
        const url = window.URL.createObjectURL(new Blob([res]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `team_visit_report.csv`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching Employee data:", error);
      });
  };
  const getEmployeesData = async (data: any, params: any) => {
    setLoading(true);
    await visitReportList(params, data)
      .then((res) => {
        console.log(res, "response");

        setEmployeesData(res);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Employee data:", error);
        setLoading(false);
      });
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  useEffect(() => {
    const data = {
      start_date: formatCurrentStartDate(new Date()),
      end_date: formatCurrentEndDate(new Date()),
      emp_id: selectedEmpId,
      sales_office_id: selectedSalesOffice,
    };
    const params = {
      page: currentPage,
      size: employeesPerPage,
    };
    getEmployeesData(data, params);
  }, [currentPage]);

  const handleApply = () => {
    const data: {
      start_date: string;
      end_date: string;
      status?: string;
      subordinate_ids?: Array<any>;
      sales_office_id?: string;
    } = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      sales_office_id: selectedSalesOffice,
    };

    if (status) {
      data["status"] = status;
    }

    if (selectedEmpId) {
      data["subordinate_ids"] = [selectedEmpId];
    }

    if (selectedSalesOffice) {
      data["sales_office_id"] = selectedSalesOffice;
    }

    const params = {
      page: currentPage,
    };

    getEmployeesData(data, params);
    handleClose();
  };

  function setStatusQuery(value: string): void {
    throw new Error("Function not implemented.");
  }

  return (
    <DashboardPage>
      <div className="bg-gray-50  sm:pl-0 pr-4 ">
        <div className="hidden mb-2 sm:flex flex-col sm:flex-row justify-between items-center bg-white p-2 border-b-2">
          <div className="w-full sm:w-auto mb-4 sm:mb-0 pl-6">
            <PaginationComponent
              totalCount={employees?.total}
              itemsPerPage={employees?.size}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>

          <div className="flex flex-col sm:flex-row items-center w-full sm:w-auto space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="relative w-full sm:w-auto">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search"
                className="w-full sm:w-auto px-4 py-2 text-sm border rounded text-gray-600"
                style={{ borderColor: "#e0e0e0" }}
              />
            </div>

            {/* Buttons group for desktop */}
            <div className="hidden sm:flex space-x-4 items-center">
              <button
                onClick={handleSort}
                className="flex items-center px-4 py-2 text-sm text-gray-600 rounded transition-all"
              >
                <img src={Sort} alt="Sort Icon" className="w-4 h-4 mr-2" />
                Sort by
              </button>

              <button
                onClick={handleFilterClick}
                className="flex items-center px-4 py-2 text-sm text-gray-600 rounded transition-all"
              >
                <img src={Filter} alt="Filter Icon" className="w-4 h-4 mr-2" />
                Filter
              </button>

              <button
                onClick={handleExport}
                className="flex items-center px-4 py-2 text-sm text-gray-600 rounded transition-all"
              >
                <img src={Export} alt="Export Icon" className="w-4 h-4 mr-2" />
                Export
              </button>
            </div>
          </div>
        </div>
        <div className="sm:hidden flex justify-between items-center p-2 border-b-2 bg-white">
          <div className="flex-grow">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search"
              className="w-full px-4 py-2 text-sm border rounded text-gray-600"
              style={{ borderColor: "#e0e0e0" }}
            />
          </div>

          {/* Hamburger menu for Sort, Filter, Export */}
          <div className="flex items-center space-x-2">
            <button
              onClick={handleExport}
              className="px-2 py-2 text-gray-600 rounded transition-all"
            >
              <img src={Export} alt="Export Icon" className="w-4 h-4" />
            </button>

            <button
              className="px-2 py-2 text-gray-600 rounded transition-all"
              onClick={() => setMobileMenuOpen(!MobileMenuOpen)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        {open && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end items-start z-50">
            <div className="bg-white shadow-lg p-12 w-full max-w-md h-screen">
              <div className="text-lg font-semibold text-gray-800">Filters</div>
              <div className="mt-4 space-y-4">
                <div>
                  <label className="block text-gray-700 mb-1">
                    Employee Id
                  </label>
                  <select
                    className="w-full border border-gray-300 rounded p-2"
                    value={selectedEmpId}
                    onChange={(e) => setSelectedEmpId(e.target.value)}
                  >
                    <option value={""}>All Employee Id</option>
                    {employees?.filters?.employees.map((emp: any) => {
                      return (
                        <option key={emp.emp_id} value={emp.emp_id}>
                          {emp.emp_id}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* Sales Office and Region */}
                <div className="flex space-x-4">
                  <div className="w-1/2">
                    <label className="block text-gray-700 mb-1">
                      Sales Office
                    </label>
                    <select
                      className="w-full border border-gray-300 rounded p-2"
                      value={selectedSalesOffice}
                      onChange={(e) => setSelectedSalesOffice(e.target.value)}
                    >
                      <option value={""}>All Sales Office</option>
                      {employees?.filters?.sales_offices.map((sales: any) => {
                        return (
                          <option
                            key={sales.sales_office_id}
                            value={sales.sales_office_id}
                          >
                            {sales.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="w-1/2">
                    <label className="block text-gray-700 mb-1">Status</label>
                    <select
                      className="w-full border border-gray-300 rounded p-2"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value={""}>Select status</option>
                      <option value={"A"}>Active</option>
                      <option value={"I"}>Inactive</option>
                    </select>
                  </div>
                </div>

                {/* Creation Date */}
                <div className="date-range-picker space-y-2">
                  {/* <label className="block text-gray-700">Creation Date:</label> */}
                  <div className="flex space-x-4">
                    <div>
                      <label>Start Date:</label>
                      <input
                        type="date"
                        className="border border-gray-300 rounded p-1"
                        value={startDate}
                        onChange={handleStartDateChange}
                      />
                    </div>
                    <div>
                      <label>End Date:</label>
                      <input
                        type="date"
                        className="border border-gray-300 rounded p-1"
                        value={endDate}
                        onChange={handleEndDateChange}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <TimeFrameSelector
                    customClass={"w-full border border-gray-300 rounded p-2"}
                    setSelectTimeFrame={setSelectTimeFrame}
                    timeFrame={timeFrame}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </div>
              </div>
              <br />
              <div className="flex justify-between mt-4">
                <button
                  onClick={handleClose}
                  className="px-4 py-2 text-white bg-gray-400 rounded transition duration-200 hover:bg-gray-500"
                >
                  Cancel
                </button>
                <button
                  onClick={handleApply}
                  className="px-4 py-2 text-white bg-blue-500 rounded transition duration-200 hover:bg-blue-600"
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="h-[calc(100vh-200px)]  md:h-[calc(100vh-120px)] overflow-auto  page-container p-2 pt-0 scrollbar scrollbar-thin ">
          <table className="min-w-full bg-white border border-black-200 table-auto">
            <thead className="bg-gray-200 sticky top-[0px] z-20">
              <tr>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-nowrap ">
                  Salesman Id
                </th>
                {/* <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 ">
                  Status
                </th> */}
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 ">
                  Salesman Name
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 ">
                  Sales Office
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 ">
                  Region
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 ">
                  Total&nbsp;Visits
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 ">
                  Count&nbsp;of <br /> Orders
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Total
                  <br />
                  Planned&nbsp;Visits
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Unique customer order
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Order with visit
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Order without visit
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Collection visit
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Collected amount
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                   Failed visit
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Total COD orders
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Total COD orders value
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  COD orders value
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  COD orders with quantity(CAR)
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Total orders value
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Total COD with quantity(CAR)
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Total cancelled order
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Cancelled order quantity (unit)
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Cancelled order value
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Total pending order
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Pending order quantity(Unit)
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Pending order quantity(CAR)
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Pending order value
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Total delivered order
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Total delivered order quantity
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Delivered order value
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  200ML sold quantity
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  330ML sold quantity
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  600ML sold quantity
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  500ML sold quantity
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  5/12 LTR sold quantity
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  1.5 LTR sold quantity
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Glass sold quantity
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Other items sold quantity
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Total unique items sold
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  First visit
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Last visit
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Total time
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Clock in time
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  Clock out time
                </th>
                <th className="px-4 py-3 border text-left text-sm font-bold text-black-500 whitespace-normal w-24">
                  COD order percentage
                </th>
              </tr>
            </thead>
            <tbody>
              {employees?.items?.length > 0 &&
                employees.items.map((employee: any) => (
                  <tr key={employee.emp_id} className="text-sm	hover:bg-gray-50">
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.emp_id}
                    </td>
                    {/* <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.status || "N/A"}
                    </td> */}
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.employee_name_english || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sales_office || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sales_region || "N/A"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_orders || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_orders_quantity || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_planned_visits || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.unique_customers_with_orders || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_orders_with_visits || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_orders_without_visits || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_collection_visits || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.amount_collected || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">{employee.failed_visits || 0}</td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_cash_on_delivery_orders || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.cash_on_delivery_orders_value || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                    {employee.cash_on_delivery_orders_value ||0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.cash_on_delivery_orders_quantity || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_orders_value || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_orders_quantity || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_cancelled_orders || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                    {employee.cancelled_orders_quantity_units ||0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_cancelled_orders_value || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_pending_orders || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.pending_orders_quantity_units || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.pending_orders_quantity_cartons || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_pending_orders_value || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_delivered_orders || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.delivered_orders_quantity_cartons || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_delivered_orders_value || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_200ml || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_330ml || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_600ml || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_500ml || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_5_by_12_ltr || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_1_half_ltr || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_glass || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.sold_quantity_other || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_unique_items_sold || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.first_visit_checkin_time || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.last_visit_checkin_time || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.total_time_spent_hours || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.begin_day_time || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.end_day_time || 0}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {employee.cash_on_delivery_percentage || 0}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {employees?.items?.length === 0 && !loading && (
            <>
              <div className="px-4 py-3 border text-center text-black-600 w-full flex items-center justify-center">
                No results found
              </div>
            </>
          )}
          {loading && <Loader />}
        </div>
        <div className="sm:hidden bg-white p-2 border-t-2 mt-auto">
          <PaginationComponent
            totalCount={employees?.total}
            itemsPerPage={employees?.size}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </DashboardPage>
  );
};

export default VisitReport;
