import React from "react";

interface ButtonProps {
  label: string;
  type?: "primary" | "secondary" | "danger";
  disabled?: boolean;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  label,
  type = "secondary",
  disabled = false,
  onClick,
}) => {
  const baseClasses =
    "px-4  py-2  focus:outline-none transition-all duration-200 ease-in-out h-max	";
  const disabledClasses = "opacity-50 cursor-not-allowed";

  const typeClasses = {
    primary: "bg-cyan  active:bg-blue-700",
    secondary: "bg-lightgrey hover:bg-gray-600 active:bg-gray-700",
    danger: "bg-red-500 hover:bg-red-600 active:bg-red-700",
  };

  return (
    <button
      className={`${baseClasses} ${typeClasses[type]} ${
        disabled ? disabledClasses : ""
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Button;
