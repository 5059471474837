

import axios from "./config";
import { getHeader ,getUserInfo } from "../utility/index";



interface ApiResponse {
    data: any; 
  }


const getLeaderBoardList = async (param:any,data:any): Promise<ApiResponse> => {
    const user_info= getUserInfo()
    const response = await  axios.post<ApiResponse>(`/supervisors/${user_info.emp_id}/leaderboard`,data, {
        params: param, 
       headers:getHeader(),
    });
    return response; 
};

const getDashboardKpiData = async (data:any): Promise<ApiResponse> => {
    const user_info= getUserInfo()
    const response = await  axios.post<ApiResponse>(`/supervisors/${user_info.emp_id}/dashboard`,data, {
       headers:getHeader(),
    });
    return response; 
};

const getDashboardMatrics = async (param:any): Promise<ApiResponse> => {
    const user_info= getUserInfo()
    const response = await axios.get<ApiResponse>(`/supervisors/${user_info.emp_id}/dashboard-metrics`, {
       headers:getHeader(),
       params:param
    });
    return response; 
};


export default getLeaderBoardList;
export {getDashboardMatrics ,getDashboardKpiData}