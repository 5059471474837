import React, { useState, useEffect } from "react";
import Button from "../../components/Button";
import OtpVerification from "./otpVerification";
import ImageSection from "./imageSection";
import { getQrCode, googleAuthVerify } from "../../api/login";
import { useLocation, useNavigate } from "react-router-dom";
import URL_MAPPING from "../../routes/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const GoogleAuth = () => {
  const [showOtpScreen, setShowOtpScreen] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [qrCode, setQrCode] = useState<string>();
  const [error, setError] = useState<string>();
  const location = useLocation();
  const { token } = location.state || {};
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBack = (): void => {
    setShowOtpScreen(false);
  };

  const handleOtpVerificaiton = async (otp: any) => {
    const pin = otp?.join("");
    if (!pin) {
      return setError("Please enter MFA code");
    }
    setLoader(true);
    await googleAuthVerify(token, pin)
      .then((res) => {
        setLoader(false);
        toast.success("Success");
        toast.success("Redirecting to the login page!");
        if (res?.data?.mfa_enabled && res?.data?.password_enabled) {
          setTimeout(() => {
            navigate(URL_MAPPING.LOGIN);
          }, 3000);
        } else {
          setError("Invalid MFA Code");
        }
      })
      .catch((error) => {
        setError("Invalid MFA Code");
        setLoader(false);
        console.log(error);
      });
  };

  const getQrCodeinit = async () => {
    await getQrCode(token)
      .then((res) => {
        setQrCode(res.data.qr_code_base64);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getQrCodeinit();
  }, []);

  return (
    <>
      <ToastContainer />
      {showOtpScreen ? (
        <>
          <div className="flex h-screen flex-col md:flex-row">
            <div className="md:w-1/2 flex-col relative w-full h-[120px] md:h-full">
              <ImageSection />
            </div>
            <OtpVerification
              handleSubmit={handleOtpVerificaiton}
              loader={loader}
              handleBack={handleBack}
              error={error}
            />
          </div>
        </>
      ) : (
        <>
          <div className="m-auto p-2">
            <div className="max-w-[892px] m-auto mt-20 flex flex-col gap-10">
              <div>
                <div className="font-semibold text-2xl">
                  {t("GoogleAuth.setAuthApp")}
                </div>
                <div className="text-20 ">{t("GoogleAuth.virtualMfa")}</div>
              </div>
              <div className="flex gap-5 flex-col md:flex-row items-center md:items-start">
                <div>
                  <div className="flex gap-2">
                    <div className="bg-lightgrey w-[46px] min-w-[36px]  h-[36px] flex justify-center items-center rounded-full align-center">
                      1
                    </div>
                    <p>{t("GoogleAuth.installApp")}</p>
                  </div>
                  <div className="flex gap-2">
                    <div className="bg-lightgrey w-[46px] min-w-[36px] h-[36px] flex justify-center items-center rounded-full align-center">
                      2
                    </div>
                    <p>{t("GoogleAuth.openApp")}</p>
                  </div>
                </div>
                <div>
                  <img className="max-w-[250px] " src={qrCode} alt="Qr code" />
                </div>
              </div>
              <div className="flex justify-end">
                <Button
                  label={t("GoogleAuth.next")}
                  type="primary"
                  onClick={() => setShowOtpScreen(true)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GoogleAuth;
