import React from "react";

interface ProgressBarProps {
  firstAmount: number | string;
  secondAmount: number | string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  firstAmount,
  secondAmount,
}) => {
  const totalAmount = Number(firstAmount) + Number(secondAmount);
  const firstPercentage = ( Number(firstAmount) / totalAmount) * 100;
  const secondPercentage = (Number(secondAmount) / totalAmount) * 100;

  return (
    <div className="flex h-[90%]  p-4">
      <div className="flex flex-col gap-6 w-full">
        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            <div className="h-12  w-[10px] bg-cugreen rounded"></div>
            <div className="flex flex-col gap-1">
              <p>Preseller</p>
              <h1 className="font-bold tex-20">{firstAmount} SAR</h1>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="h-12  w-[10px] bg-cugreen rounded"></div>
            <div className="flex flex-col gap-1">
              <p>Salesman</p>
              <h1 className="font-bold tex-20">{secondAmount} SAR</h1>
            </div>
          </div>
        </div>
        <div>
          <p className="font-bold">Total Sales</p>
          <h1 className="text-purple">{totalAmount} SAR</h1>
        </div>
      </div>
      <div className="w-10 min-w-[5px] bg-gray-200 rounded relative md:h-full h-[200px]">
        <div
          className="absolute bottom-0 left-0 w-full bg-blue-500 rounded-b"
          style={{ height: `${firstPercentage}%` }}
        />
        <div
          className="absolute bottom-0 left-0 w-full bg-cyan rounded-t"
          style={{
            height: `${secondPercentage}%`,
            bottom: `${firstPercentage}%`,
          }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
