import React, { useState, FormEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import OpenEye from "../../assets/open-eye.svg";
import CloseEye from "../../assets/close-eye.svg";
import { loginOtpVerify, verifyMail, resetPass } from "../../api/login";
import Button from "../../components/LoaderButton";
import OtpVerification from "./otpVerification";
import URL_MAPPING from "../../routes/constants";
import ImageSection from "./imageSection";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../components/LangSelector";

const Index: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const [optScreen, setOtpScreen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isRTL, setIsRTL] = useState(false);

  const handleNavigate = (): void => {
    navigate(URL_MAPPING.SIGNUP, { state: { isForgetPass: true } });
  };

  const handleBack = (): void => {
    setOtpScreen(false);
  };

  const handleOtpVerification = async (otp: any): Promise<void> => {
    const otpTo = otp.join("");
    setLoader(true);
    await loginOtpVerify({
      email: username,
      password: password,
      mfa_code: otpTo,
    })
      .then((res) => {
        Cookies.set("token", res?.data?.access_token);
        Cookies.set("refresh_token", res?.data?.refresh_token);
        navigate(URL_MAPPING.LANDING);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        setError("wrong_all_cred");
      });
  };

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!username) {
      return setError("enter_email");
    } else if (!emailRegex.test(username)) {
      return setError("valid_email");
    } else {
      setError("");
    }
    if (!password) {
      return setError("enter_password");
    } else {
      setError("");
    }

    setLoader(true);
    await verifyMail({ email: username })
      .then((res) => {
        if (res?.data?.mfa_enabled && res?.data?.password_enabled) {
          setOtpScreen(true);
        } else {
          setError("wrong_cred");
        }
      })
      .catch(() => {
        setError("wrong_cred");
      });

    setLoader(false);
  };

  useEffect(() => {
    if (Cookies.get("token")) {
      navigate(URL_MAPPING.LANDING);
    }
  }, []);

  const errotTypeTranslation =(error:string)=>{
    switch (error){
      case 'enter_email':
        return(t('LoginError.entaerEmail'));
      case 'enter_password':
        return(t('LoginError.enterPassword'));
      case 'valid_email':
        return(t('LoginError.enterValidEmail'));
      case 'wrong_cred':
        return(t('LoginError.wrongCred'));
      case 'wrong_all_cred':
        return(t('LoginError.wrongAllCred'));
    }
    
  }

  useEffect(() => {
    let isRtl = false;
    const dir = document.documentElement.getAttribute("dir");
    isRtl = dir === "rtl";
    setIsRTL(isRtl);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.documentElement.getAttribute("dir")]);

  const handleKeyDown = (event:any) => {
    if (event.key === 'Enter') {
      handleSubmit(event);  
    }
  };

  return (
    <>
      <div className="flex h-screen flex-col md:flex-row">
        <div className="md:w-1/2 flex-col relative w-full h-[120px] md:h-full">
          <ImageSection />
        </div>
        {optScreen === false ? (
          <div className="relative w-full md:w-1/2 flex flex-col justify-center items-center bg-white md:p-24 sm:p-14 p-10">
            <div className="mb-8">
              <img src={logo} alt="Logo" className="h-18" />
            </div>
            <div className="absolute top-[-12%] md:top-10 right-[13%]">
              <LanguageSelector />
            </div>
            <h2 className="text-2xl font-bold mb-6 text-purple">
              {t("SignUp.signIn")}
            </h2>
            <div className="mb-4 w-full">
              <label className="block text-gray-700 text-13 font-semibold">
                {t("SignUp.email")}
              </label>
              <input
                type="text"
                value={username}
                onKeyDown={(e)=>handleKeyDown(e)}
                placeholder={t("SignUp.enterEmail")}
                onChange={(e) => setUsername(e.target.value)}
                className="text-13 w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 bg-lightgrey h-12"
              />
            </div>
            <div className="mb-2 w-full relative">
              <label className="block text-gray-700 text-13 font-semibold">
                {t("SignUp.password")}
              </label>
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onKeyDown={(e)=>handleKeyDown(e)}
                required
                placeholder={t("SignUp.enterPassword")}
                onChange={(e) => setPassword(e.target.value)}
                className="text-13 w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 bg-lightgrey h-12"
              />
              <button
                className={`absolute top-11 transform -translate-y-1/2 ${
                  isRTL ? "left-3" : "right-3"
                }`}
                onClick={() => setShowPassword(!showPassword)}
                type="button"
              >
                {showPassword ? (
                  <img src={CloseEye} alt="Hide password" />
                ) : (
                  <img src={OpenEye} alt="Show password" />
                )}
              </button>
            </div>

            <div className="mb-4 w-full text-end ">
              <p
                onClick={handleNavigate}
                className="text-base hover:underline cursor-pointer font-semibold"
              >
                {t("SignUp.forgetPassword")}
              </p>
            </div>

            <div className="mb-4 w-full text-red-600 text-sm text-center mt-6">
              {errotTypeTranslation(error)}
            </div>

            <Button
              text="Sign In"
              onClick={handleSubmit}
              isLoading={loader}
              disabled={loader}
            />
            {/* <div className="mt-8 text-13 text-grey flex flex-col md:flex-row gap-1 items-center">
              {t("SignUp.dontHaveAccount")}
              <span
                className="text-purple font-bold text-base cursor-pointer"
                onClick={() =>
                  navigate(URL_MAPPING.SIGNUP, {
                    state: { isForgetPass: false },
                  })
                }
              >
                {t("SignUp.requestAccount")}
              </span>
            </div> */}
          </div>
        ) : (
          <OtpVerification
            handleSubmit={handleOtpVerification}
            loader={loader}
            handleBack={handleBack}
            error={errotTypeTranslation(error)}
          />
        )}
      </div>
    </>
  );
};

export default Index;
