import React from "react";
import { ResponsiveBar } from "@nivo/bar";

interface BarChartProps {
  data: any; // Consider making this more specific if possible
  barColors: string[]; // Assuming barColors is an array of strings representing colors
}

const BarChart: React.FC<BarChartProps> = ({ data, barColors }) => {
  return (
    <div style={{ height: "200px" }}>
      <ResponsiveBar
        data={data}
        keys={["value"]}
        indexBy="visits"
        margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
        padding={0.6}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={({ index }) => barColors[index]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "visits",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        enableLabel={false}
        
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Value",
          legendPosition: "middle",
          legendOffset: -40,
          tickValues: [0, 30, 60, 90, 120, 150, 180], // Set y-axis values
        }}
        
        enableGridX={false} 
        enableGridY={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        label={()=>''}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        legends={[
          // Uncomment this if you want legends
          // {
          //   dataFrom: "keys",
          //   anchor: "bottom-right",
          //   direction: "column",
          //   justify: false,
          //   translateX: 120,
          //   translateY: 0,
          //   itemsSpacing: 2,
          //   itemWidth: 100,
          //   itemHeight: 20,
          //   itemDirection: "left-to-right",
          //   itemOpacity: 0.85,
          //   symbolSize: 20,
          //   effects: [
          //     {
          //       on: "hover",
          //       style: {
          //         itemOpacity: 1,
          //       },
          //     },
          //   ],
          // },
        ]}
        animate={true}
      />
    </div>
  );
};

export default BarChart;
