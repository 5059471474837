import URL_MAPPING from "../routes/constants";
import axios from "axios";
import Cookies from "js-cookie";


const env = process.env.REACT_APP_ENV; 

let baseURL;
let baseUrlGps;
let environment;

if (env === 'development') {
    baseURL = process.env.REACT_APP_DEV_API_URL;
    baseUrlGps = process.env.REACT_APP_DEV_GPS_API_URL;
    // environment = process.env.REACT_APP_DEV_ENV;
} else if (env === 'staging') {
    baseURL = process.env.REACT_APP_STAGING_API_URL;
    baseUrlGps = process.env.REACT_APP_STAGING_GPS_API_URL;
    // environment = process.env.REACT_APP_STAGING_ENV;
} else if (env === 'prod') {
    baseURL = process.env.REACT_APP_PROD_API_URL;
    baseUrlGps = process.env.REACT_APP_PROD_GPS_API_URL;
    // environment = process.env.REACT_APP_PROD_ENV;
} else {
  console.error('No valid environment specified');
}


const header = {
    Authorization: Cookies.get("token"),
};

const redirectToLogin = () => {
    alert("Authorization Failed! You have been logged out");

    setTimeout(() => {
        localStorage.clear();
        Cookies.remove("token");
        window.location.href = URL_MAPPING.LOGIN;
    }, 2000);
};

const instance = axios.create({
    baseURL: baseURL,
    headers: header,
});

const instanceGps = axios.create({
    baseURL: baseUrlGps,
    headers: header,
});




instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error?.response?.status === 401) {
        //    refreshAcessToken()
        }
        if (error?.response?.status === 500){
            alert('Something went wrong. Please try again.')
        }
        return Promise.reject(error);
    }
);

export default instance;
export {instanceGps}