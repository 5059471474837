import React from "react";
import Loader from "./Loader";
import { formatNumber } from "../utility";

type Leader = {
  id: number;
  emp_id: string;
  name_eng: string;
  percentage_sold: number;
  cartons_sold: number;
  cartons_target: number;
};

type LeaderTableProps = {
  leaderData: any;
  loader: boolean;
  getIndex: (leaderData: any, index: number) => number;
};

const LeaderTable: React.FC<LeaderTableProps> = ({
  leaderData,
  loader,
  getIndex,
}) => {
  return (
    <>
      <table className="min-w-full table-auto text-left">
        <thead className="sticky top-0 w-full">
          <tr className="bg-[#DEDEDE] text-white">
            <th className="px-4 text-black py-2 text-13 font-bold">#</th>
            <th className="px-4 text-black py-2 text-13 font-bold">
              Employee Name/Id
            </th>
            <th className="px-4 text-black py-2 text-13 font-bold max-w-[130px]">
              Actual/ Target
            </th>
            <th className="px-4 text-black py-2 text-13 font-bold">%</th>
          </tr>
        </thead>
        <tbody>
          {leaderData?.map((leader: Leader, index: number) => (
            <tr key={index} className="bg-white border-b">
              <td className="px-4 py-2 text-gray-700 text-13 font-bold">
                {getIndex(leaderData, index)}
              </td>
              <td className="px-4 py-2 text-gray-700 text-13 font-bold max-w-[180px]">
                <div className="flex flex-col">
                  <p className="font-bold">{leader.name_eng}</p>
                  <p className="text-[10px] font-medium">{leader.emp_id}</p>
                </div>{" "}
              </td>
              <td className="px-4 py-2 text-gray-700 text-13 font-bold">
                {formatNumber(leader?.cartons_sold)}/
                {formatNumber(leader?.cartons_target)}
              </td>
              <td className="px-4 py-2 text-gray-700 text-13 font-bold">
                {leader.percentage_sold}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>{loader && <Loader />}</div>
    </>
  );
};

export default LeaderTable;
