import React from 'react';

interface ImageComparisonProps {
    actualImage: string;
    processedImage: string;
    comparisonImage: string;
    onClose: () => void;
}

const PlanogramPopup: React.FC<ImageComparisonProps> = ({ actualImage, processedImage, onClose }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={onClose}>
            <div className="bg-white rounded-lg p-6 max-w-4xl w-full" onClick={(e) => e.stopPropagation()}>
                <div className="flex justify-between items-center pb-4 border-b">
                    <h2 className="text-xl font-semibold">Image Comparison</h2>
                    <button className="text-2xl font-bold" onClick={onClose}>×</button>
                </div>
                <div className="flex justify-around mt-6 space-x-6">
                    <div className="text-center">
                        <h3 className="mb-4 text-lg font-bold text-left">Actual Image</h3>
                        <img src={actualImage} alt="Actual" className="w-64 h-64 object-cover bg-gray-300 rounded-lg" />
                    </div>
                    <div className="text-center">
                        <h3 className="mb-4 text-lg font-bold text-left">Processed Image</h3>
                        <img src={processedImage} alt="Processed" className="w-64 h-64 object-cover bg-gray-300 rounded-lg" />
                    </div>
                    <div className="text-center">
                        <h3 className="mb-4 text-lg font-bold text-left">All images</h3>
                        <img src={processedImage} alt="All images" className="w-64 h-64 object-cover bg-gray-300 rounded-lg" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlanogramPopup;

