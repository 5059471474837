import DashboardPage from '../../layout';
import React, { useState } from "react";
import Dashboard from '../../layout';
import GoogleMap from "../../components/GoogleMap";
import { useTranslation } from 'react-i18next';

type Territory = {
  id: number;
  name: string;
  area: string;
};

type Customer = {
  name: string;
  address: string;
  contact: string;
};

const TerritoryPlanner: React.FC = () => {
  const { t } = useTranslation();

  const [selectedTerritory, setSelectedTerritory] = useState<Territory | null>(null);
  const [searchTerritory, setSearchTerritory] = useState("");
  const [searchCustomer, setSearchCustomer] = useState("");

  const territories: Territory[] = [
    { id: 143, name: "Territory 143", area: "Nad Al Sheba" },
    { id: 534, name: "Territory 534", area: "Jumeirah" },
    { id: 152, name: "Territory 152", area: "Al Satwa" },
  ];

  const customers: Customer[] = [
    { name: "Customer 1", address: "Address 1", contact: "Contact 1" },
    { name: "Customer 2", address: "Address 2", contact: "Contact 2" },
    { name: "Customer 3", address: "Address 3", contact: "Contact 3" },
  ];

  const filteredTerritories = territories.filter((territory) =>
    territory.name.toLowerCase().includes(searchTerritory.toLowerCase())
  );

  const filteredCustomers = customers.filter((customer) =>
    customer.name.toLowerCase().includes(searchCustomer.toLowerCase())
  );

  const handleTerritoryClick = (territory: Territory) => {
    setSelectedTerritory(territory);
  };

  return (
    <Dashboard>
      <div className="flex flex-col h-full overflow-y-auto">
        <div className="flex flex-col md:flex-row justify-between items-center p-4 bg-white border-b border-gray-200">
          <select className="border border-gray-300 rounded-md p-2 bg-gray-100 w-full md:w-auto mb-2 md:mb-0">
            <option value="Q1">{t('TerritoryPlanner.currentQuarter')}</option>
          </select>
          <button className="bg-purple hover:bg-purple-600 text-white font-bold py-2 px-4 rounded w-full md:w-auto">
            {t('TerritoryPlanner.generateTerritoryPlan')}
          </button>
        </div>

        <div className="flex flex-col lg:flex-row flex-grow overflow-hidden">
          <div className="flex flex-col md:flex-row lg:w-1/3 w-full p-4 bg-gray-50 border-r border-gray-200 space-y-4 md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/2 lg:w-[264px] bg-white border border-gray-200 rounded-md flex flex-col">
              <div className="p-4 border-b border-gray-300">
                <input
                  type="text"
                  placeholder={t('TerritoryPlanner.searchTerritory')}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={searchTerritory}
                  onChange={(e) => setSearchTerritory(e.target.value)}
                />
              </div>
              <ul className="flex-1 overflow-y-auto max-h-[200px]">
                {filteredTerritories.map((territory) => (
                  <li
                    key={territory.id}
                    onClick={() => handleTerritoryClick(territory)}
                    className={`p-4 border-b border-gray-200 cursor-pointer transition ${
                      selectedTerritory?.id === territory.id ? 'bg-purple-100' : 'hover:bg-gray-100'
                    }`}
                  >
                    {territory.name}{" "}
                    <span className="text-gray-500">({territory.area})</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="w-full md:w-1/2 lg:w-[264px] bg-white border border-gray-200 rounded-md flex flex-col">
              <div className="p-4 border-b border-gray-300">
                <input
                  type="text"
                  placeholder={t('TerritoryPlanner.searchCustomer')}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={searchCustomer}
                  onChange={(e) => setSearchCustomer(e.target.value)}
                />
              </div>
              <ul className="flex-1 overflow-y-auto max-h-[200px]">
                {filteredCustomers.map((customer, index) => (
                  <li key={index} className="p-4 border-b border-gray-200">
                    <strong>{customer.name}</strong>
                    <p className="text-sm text-gray-500">{customer.address}</p>
                    <p className="text-sm text-gray-500">{customer.contact}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="flex-grow bg-gray-200 flex flex-col mt-4 lg:mt-0">
            <div className="flex-grow overflow-y-auto h-[300px] lg:h-[400px]">
              <GoogleMap />
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default TerritoryPlanner;
