import React, { useEffect, useState } from "react";
import DashboardPage from "../../layout";
import getPlanogramList from "../../api/planogram";
import PaginationComponent from "../../components/Pagination";
import MenuDots from "../../assets/menuDots.svg";
import Export from "../../assets/icons/Export.svg";
import Sort from "../../assets/icons/Sort.svg";
import Eye from "../../assets/eye.svg";
import Filter from "../../assets/icons/Filter.svg";
import Loader from "../../components/Loader";
import PlanogramPopup from "../../components/PlanogramPopup";
import { useTranslation } from "react-i18next";
import TimeFrameSelector from "../../components/TimeFrameSelector";
import { formatCurrentEndDate, formatCurrentStartDate } from "../../utility";

const Planogram: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const employeesPerPage = 15;
  const [MobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedCustomerIds, setSelectedCustomerIds] = useState<any>("");
  const [selectedSalesOffice, setSelectedSalesOffice] = useState<any>("");
  const [selectedEmployeeID, setSelectedEmployeeID] = useState<any>("");
  const [selectedImageType, setSelectedImageType] = useState("");
  const [timeFrame, setSelectTimeFrame] = useState<any>("Today");
  const [open, setOpen] = useState(false);
  const [planogram, setPlanogramData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [status, setStatus] = useState<any>("");

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleFilterClick = () => {
    setOpen(true);
  };

  const handleSort = () => {
    console.log("Sort button clicked");
  };

  const handleClose = () => setOpen(false);

  const getPlanogramData = async (params: any, data: any = {}) => {
    setLoading(true);
    await getPlanogramList(params, data)
      .then((res) => {
        console.log(res, "response");
        setPlanogramData(res);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching employee data:", error);
      });
  };

  useEffect(() => {
    const data: {
      start_date: string;
      end_date: string;
      status?: string;
      image_type?: any;
      sales_office_ids?: Array<any>;
      customer_ids?: any;
      subordinate_ids?: any;
    } = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    if (selectedSalesOffice) {
      data["sales_office_ids"] = [selectedSalesOffice];
    }
    if (selectedImageType) {
      data["image_type"] = selectedImageType;
    }
    if (selectedCustomerIds) {
      data["customer_ids"] = [selectedCustomerIds];
    }
    if (selectedEmployeeID) {
      data["subordinate_ids"] = [selectedEmployeeID];
    }
    if (status) {
      data["status"] = status;
    }

    const params = {
      page: currentPage,
      size: employeesPerPage,
    };

    if (startDate && endDate) {
      getPlanogramData(params, data);
    }
  }, [searchQuery, currentPage]);

  const handleApply = (event: React.MouseEvent<HTMLButtonElement>): void => {
    // Prepare filters object to send as payload
    const data: {
      start_date: string;
      end_date: string;
      status?: string;
      image_type?: any;
      sales_office_ids?: Array<any>;
      customer_ids?: any;
      subordinate_ids?: any;
    } = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    if (selectedSalesOffice) {
      data["sales_office_ids"] = [selectedSalesOffice];
    }
    if (selectedImageType) {
      data["image_type"] = selectedImageType;
    }
    if (selectedCustomerIds) {
      data["customer_ids"] = [selectedCustomerIds];
    }
    if (selectedEmployeeID) {
      data["subordinate_ids"] = [selectedEmployeeID];
    }
    if (status) {
      data["status"] = status;
    }

    const params = {
      page: currentPage,
      size: employeesPerPage,
    };

    getPlanogramData(params, data);
    setOpen(false);
  };

  const handleExport = async () => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      export: true,
      customer_ids: selectedCustomerIds,
      sales_office_ids: selectedSalesOffice,
      subordinate_ids: selectedEmployeeID,
    };

    if (searchQuery) {
      data["search"] = searchQuery;
    }

    const params = {};
    exportActiviyData(data, params);
  };

  const exportActiviyData = async (data: any, params: any) => {
    await getPlanogramList(params, data)
      .then((res) => {
        console.log(res, "response");
        const url = window.URL.createObjectURL(new Blob([res]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `planogram_report.csv`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching Employee data:", error);
      });
  };

  return (
    <DashboardPage>
      <div className="bg-gray-50  flex flex-col">
        {/* Top bar for desktop */}
        <div className="hidden mb-2 sm:flex flex-col sm:flex-row justify-between items-center bg-white p-2 border-b-2">
          <div className="w-full sm:w-auto mb-4 sm:mb-0 pl-6">
            <PaginationComponent
              totalCount={planogram?.total}
              itemsPerPage={planogram?.size}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>

          <div className="flex flex-col sm:flex-row items-center w-full sm:w-auto space-y-4 sm:space-y-0 sm:space-x-4 pr-10">
            <div className="relative w-full sm:w-auto">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
                className="w-full sm:w-auto px-4 py-2 text-sm border rounded text-gray-600"
                style={{ borderColor: "#e0e0e0" }}
              />
            </div>

            {/* Buttons group for desktop */}
            <div className="hidden sm:flex space-x-4 items-center">
              <button
                onClick={handleSort}
                className="flex items-center px-4 py-2 text-sm text-gray-600 rounded transition-all"
              >
                <img src={Sort} alt="Sort Icon" className="w-4 h-4 mr-2" />
                Sort by
              </button>

              <button
                onClick={handleFilterClick}
                className="flex items-center px-4 py-2 text-sm text-gray-600 rounded transition-all"
              >
                <img src={Filter} alt="Filter Icon" className="w-4 h-4 mr-2" />
                Filter
              </button>

              <button
                onClick={handleExport}
                className="flex items-center px-4 py-2 text-sm text-gray-600 rounded border-2 transition-all"
              >
                <img src={Export} alt="Export Icon" className="w-4 h-4 mr-2" />
                Export
              </button>
            </div>
          </div>
        </div>

        {/* Top bar for mobile */}
        <div className="sm:hidden flex justify-between items-center p-2 border-b-2 bg-white">
          <div className="flex-grow">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search"
              className="w-full px-4 py-2 text-sm border rounded text-gray-600"
              style={{ borderColor: "#e0e0e0" }}
            />
          </div>

          {/* Hamburger menu for Sort, Filter, Export */}
          <div className="flex items-center space-x-2">
            <button
              onClick={handleExport}
              className="px-2 py-2 text-gray-600 rounded transition-all"
            >
              <img src={Export} alt="Export Icon" className="w-4 h-4" />
            </button>

            <button
              className="px-2 py-2 text-gray-600 rounded transition-all"
              onClick={() => setMobileMenuOpen(!MobileMenuOpen)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile menu for Sort and Filter */}
        {MobileMenuOpen && (
          <div className="sm:hidden bg-white border-t-2">
            <button
              onClick={handleSort}
              className="w-full flex items-center px-4 py-2 text-sm text-gray-600 border-b transition-all"
            >
              <img src={Sort} alt="Sort Icon" className="w-4 h-4 mr-2" />
              Sort by
            </button>

            <button
              onClick={handleFilterClick}
              className="w-full flex items-center px-4 py-2 text-sm text-gray-600 border-b transition-all"
            >
              <img src={Filter} alt="Filter Icon" className="w-4 h-4 mr-2" />
              Filter
            </button>
          </div>
        )}

        {open && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end sm:items-start items-center z-50">
            <div className="bg-white shadow-lg p-6 sm:p-12 w-full max-w-md h-screen">
              <div className="text-lg font-semibold text-gray-800">Filters</div>
              <div className="mt-4 space-y-4">
                <div>
                  <label className="block text-gray-700 mb-1">Employee</label>
                  <select
                    className="w-full border border-gray-300 rounded p-2"
                    value={selectedEmployeeID}
                    onChange={(e) => setSelectedEmployeeID(e.target.value)}
                  >
                    <option value={""}>All employees</option>
                    {planogram?.filters?.employees.map((emp: any) => (
                      <option key={emp.emp_id} value={emp.emp_id}>
                        {emp.name_eng}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-gray-700 mb-1">Customer</label>
                  <select
                    className="w-full border border-gray-300 rounded p-2"
                    value={selectedCustomerIds}
                    onChange={(e) =>
                      setSelectedCustomerIds(
                        e.target.value
                      )
                    }
                  >
                    <option value={[]}>All customers</option>
                    {planogram?.filters?.customers.map((cus: any) => (
                      <option key={cus.customer_id} value={cus.customer_id}>
                        {cus.name_eng}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                  <div className="w-full sm:w-1/2">
                    <label className="block text-gray-700 mb-1">
                      Sales Office
                    </label>
                    <select
                      className="w-full border border-gray-300 rounded p-2"
                      value={selectedSalesOffice}
                      onChange={(e) =>
                        setSelectedSalesOffice(
                          e.target.value
                        )
                      }
                    >
                      <option value={""}>All sales office</option>
                      {planogram?.filters?.sales_offices.map((sales: any) => (
                        <option
                          key={sales.sales_office_id}
                          value={sales.sales_office_id}
                        >
                          {sales.sales_office_id}:{sales.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="w-full sm:w-1/2">
                    <label className="block text-gray-700 mb-1">
                      Image Type
                    </label>
                    <select
                      className="w-full border border-gray-300 rounded p-2"
                      value={selectedImageType}
                      onChange={(e) => setSelectedImageType(e.target.value)}
                    >
                      {planogram?.filters?.image_types.map((img: any) => (
                        <option key={img} value={img}>
                          {img}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="date-range-picker space-y-2">
                  <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                    <div>
                      <label>Start Date:</label>
                      <input
                        type="date"
                        className="border border-gray-300 rounded p-1"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div>
                      <label>End Date:</label>
                      <input
                        type="date"
                        className="border border-gray-300 rounded p-1"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex w-full gap-4">
                    <div className="w-full sm:w-1/2">
                      <label className="block text-gray-700 mb-2">Status</label>
                      <select
                        className="w-full border border-gray-300 rounded p-2"
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value={""}>Select status</option>
                        <option value={"A"}>Active</option>
                        <option value={"I"}>Inactive</option>
                      </select>
                    </div>
                    <div className="w-full sm:w-1/2">
                      <TimeFrameSelector
                        customClass={
                          "w-full border border-gray-300 rounded p-2"
                        }
                        setSelectTimeFrame={setSelectTimeFrame}
                        timeFrame={timeFrame}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between mt-4">
                <button
                  onClick={handleClose}
                  className="px-4 py-2 text-white bg-gray-400 rounded transition duration-200 hover:bg-gray-500"
                >
                  Cancel
                </button>
                <button
                  onClick={handleApply}
                  className="px-4 py-2 text-white bg-blue-500 rounded transition duration-200 hover:bg-blue-600"
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="h-[calc(100vh-200px)]  md:h-[calc(100vh-120px)] overflow-auto p-2 pt-0 page-container scrollbar scrollbar-thin ">
          <table className="min-w-full bg-white border border-black-200 table-auto">
            <thead className="bg-gray-200 sticky top-[0px] z-20">
              <tr>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  Reference ID
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  Sales Office ID
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  Customer ID
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500">
                  Salesman ID
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500">
                  Date of Capture
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500">
                  Time Of Capture
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500 whitespace-normal w-16 sm:w-24">
                  200ml
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500">
                  330ml
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500">
                  500ml
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500">
                  600ml
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500">
                  1.5Ltr
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500">
                  5Ltr
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500">
                  12Ltr
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500">
                  270ml Glass
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500">
                  750ml Glass
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500">
                  270ml Carbonated
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500">
                  750ml Carbonated
                </th>
                <th className="px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500">
                  Total SKU
                </th>
                <th className="sticky right-0 bg-gray-200 px-2 sm:px-4 py-3 border text-left text-xs sm:text-sm font-bold text-black-500 tracking-wider z-4">
                  View Image
                </th>
              </tr>
            </thead>
            <tbody>
              {planogram?.items?.length > 0 &&
                planogram?.items?.map((planogram: any) => (
                  <tr
                    key={planogram.items}
                    className="text-xs sm:text-sm hover:bg-gray-50"
                  >
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.reference_id}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.sales_office_id || "N/A"}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.customer_id || "N/A"}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.salesman_id || "N/A"}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.date_of_capture || "N/A"}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.time_of_capture || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ml_330_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ml_500_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ml_600_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ltr_1_5_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ltr_5_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ltr_12_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ml_270_glass_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ml_750_glass_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ml_270_carbonated_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ml_750_carbonated_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.total_sku_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.total_sku_count || 0}
                    </td>
                    <td className="sticky right-0 bg-white px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap  flex items-center justify-center ">
                      <img
                        src={Eye}
                        onClick={handleOpenPopup}
                        alt="Action Item"
                        className="h-5 w-5 sm:h-6 sm:w-6"
                      />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {planogram?.items?.length === 0 && !loading && (
            <>
              <div className="px-4 py-3 border text-center text-black-600 w-full flex items-center justify-center">
                No results found
              </div>
            </>
          )}
          {loading && <Loader />}
          {isPopupOpen && (
            <PlanogramPopup
              actualImage={planogram.items.actual_image_path}
              processedImage={planogram.items.processed_image_path}
              comparisonImage={planogram.items.processed_image_path}
              onClose={handleClosePopup}
            />
          )}
        </div>

        <div className="sm:hidden bg-white p-2 border-t-2 mt-auto">
          <PaginationComponent
            totalCount={planogram?.total}
            itemsPerPage={planogram?.size}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </DashboardPage>
  );
};

export default Planogram;
