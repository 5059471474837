import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';


export function getUserInfo() {
    const token = Cookies.get('token') || ''
    const decoded = jwtDecode(token);
    const parse=JSON.stringify(decoded)
    return JSON.parse(parse)
}
export function getHeader() {
    const token = Cookies.get('token') || ''
    const header = {
        Authorization:  token,
      };
    return header
}

export function formatCurrentStartDate (date: any)  {
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, "0");
    const day = String(date?.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}T00:00:00.000Z`;
  };


export function formatCurrentEndDate (date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); 
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}T23:59:59.000Z`;
  };


export function useRTL  (isArabic:any)  {
  const direction = isArabic ? 'rtl' : 'ltr';
  document.documentElement.setAttribute('dir', direction);
};


export function currentLang  ()  {
  const lang =localStorage.getItem('lang');
  return lang
};

export  function formatDate(inputdate: any) {
  const date = new Date(inputdate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export  function formatNumber (number:any) {
  return (new Intl.NumberFormat('en-US', {
    // minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(Number(number)))
};

export default function utility(){ }