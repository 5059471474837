import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

const ErrorPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initialize i18n hook

  const handleReturn = () => {
    navigate(-1); 
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-red-600">404</h1> {/* No translation needed for "404" */}
        <p className="mt-4 text-xl text-gray-700">
          {t('Error.errorPageMessage')} {/* Use translation key */}
        </p>
        <button
          onClick={handleReturn}
          className="mt-6 px-6 py-3 text-white bg-blue-600 hover:bg-blue-700 rounded-lg shadow-md"
        >
          {t('Error.goBack')} {/* Use translation key */}
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
