import React from 'react';

interface VisitSummaryCardProps {
  title: string;
  value: number | undefined;
  backgroundColor: string;
  textColor: string;
}

const VisitSummaryCard: React.FC<VisitSummaryCardProps> = ({ title, value, backgroundColor, textColor }) => {
  return (
    <div className={`max-w-none md:max-w-[180px] flex flex-col justify-center w-full ${backgroundColor}`}>
      <div className="pt-2 font-bold px-4">
        {title}
      </div>
      <div className={`px-4 text-36 font-bold ${textColor}`}>
        {value}
      </div>
    </div>
  );
};

export default VisitSummaryCard;
