import React, { useEffect } from "react";
import { formatDate } from "../utility";
interface TimeFrameSelectorProps {
  timeFrame: string;
  customClass?: string;
  setSelectTimeFrame: (value: string) => void;
  setStartDate: (date: string) => void ;
  setEndDate: (date: string) => void;
}

const TimeFrameSelector: React.FC<TimeFrameSelectorProps> = ({
  timeFrame,
  setSelectTimeFrame,
  setStartDate,
  setEndDate,
  customClass
}) => {
 
  useEffect(() => {
    if (timeFrame === "Today") {
      setStartDate(formatDate(new Date()));
      setEndDate(formatDate(new Date()));
    } else if (timeFrame === "Yesterday") {
      setStartDate(formatDate(new Date().setDate(new Date().getDate() - 1)));
      setEndDate(formatDate(new Date().setDate(new Date().getDate() - 1)));
    } else if (timeFrame === "Month till date") {
      setStartDate(formatDate(new Date().setDate(1)));
      setEndDate(formatDate(new Date()));
    }
  }, [timeFrame]);

 
  return (
    <>
      <div className="flex flex-col gap-2">
        <label className="block text-gray-700">
          Timeframe
        </label>
        <select
          className={`text-base font-medium  ${customClass}`}
          value={timeFrame}
          onChange={(e) => setSelectTimeFrame(e.target.value)}
        >
          <option value="">select</option>
          <option value="Today">Today</option>
          <option value="Yesterday">Yesterday</option>
          <option value="Month till date">Month till date</option>
        </select>
      </div>
    </>
  );
};

export default TimeFrameSelector;
