import axios from "./config";
import { getHeader ,getUserInfo } from "../utility/index";

interface Customer {
  
}

interface ApiResponse {
  data: Customer[];
}
interface DecodedToken {
  emp_id: string; 
}

const getCustomerList = async (params:any, data:any): Promise<any> => {
  const user_info = getUserInfo();

  const response = await axios.post<any>(`/customer/${user_info.emp_id}/customers-report`,data ,  {
    headers: getHeader(),
    params: params
  });
  return response.data;
};

export default getCustomerList;

