import React, { useEffect } from "react"; // Import React (optional in new versions but good practice)
import "./App.css";
import "./style.css";
import { BrowserRouter as ReactRoutes, Route, Routes } from "react-router-dom";
import AllRoutes from "./routes/routes";
import { refreshToken } from "./api/login";
import Cookies from "js-cookie";
import { NotificationProvider } from "./notification";
import "react-toastify/dist/ReactToastify.css";

const App: React.FC = () => {
  const refreshAcessToken = async () => {
    await refreshToken()
      .then((res) => {
        Cookies.set("token", res?.data?.access_token);
        Cookies.set("refresh_token", res?.data?.refresh_token);
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      refreshAcessToken();
    }
    const intervalId = setInterval(refreshAcessToken, 300000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <NotificationProvider>
      <ReactRoutes>
        <AllRoutes />
      </ReactRoutes>
    </NotificationProvider>
  );
};

export default App;
