import React from "react";

const TableComponent = () => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full table-auto border-collapse">
        <thead className="bg-lightgrey">
          <tr>
            <th className="px-4 py-2 border-b text-left">Invoice Id</th>
            <th className="px-4 py-2 border-b text-left">Invoice By</th>
            <th className="px-4 py-2 border-b text-left">Invoice Date</th>
            <th className="px-4 py-2 border-b text-left">Amount</th>
            <th className="px-4 py-2 border-b text-left"></th>
            <th className="px-4 py-2 border-b text-left">Action</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          <tr>
            <td className="px-4 py-2 border-b border-lightgrey">#2024_65264</td>
            <td className="px-4 py-2 border-b border-lightgrey">Rahim Khan</td>
            <td className="px-4 py-2 border-b border-lightgrey">22 May 2024</td>
            <td className="px-4 py-2 border-b border-lightgrey">230 AED</td>
            <td className="px-4 py-2 border-b border-lightgrey">In Process</td>
            <td className="px-4 py-2 border-b border-lightgrey">
              <button className="text-gray-600">&#x22EE;</button>
            </td>
          </tr>
          <tr>
            <td className="px-4 py-2 border-b border-lightgrey">#2024_65264</td>
            <td className="px-4 py-2 border-b border-lightgrey">Rahim Khan</td>
            <td className="px-4 py-2 border-b border-lightgrey">22 May 2024</td>
            <td className="px-4 py-2 border-b border-lightgrey">230 AED</td>
            <td className="px-4 py-2 border-b border-lightgrey">In Process</td>
            <td className="px-4 py-2 border-b border-lightgrey">
              <button className="text-gray-600">&#x22EE;</button>
            </td>
          </tr>
          <tr>
            <td className="px-4 py-2 border-b border-lightgrey">#2024_65264</td>
            <td className="px-4 py-2 border-b border-lightgrey">Rahim Khan</td>
            <td className="px-4 py-2 border-b border-lightgrey">22 May 2024</td>
            <td className="px-4 py-2 border-b border-lightgrey">230 AED</td>
            <td className="px-4 py-2 border-b border-lightgrey">In Process</td>
            <td className="px-4 py-2 border-b border-lightgrey">
              <button className="text-gray-600">&#x22EE;</button>
            </td>
          </tr>
         
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
