import { useRTL } from "../utility";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector: React.FC = () => {
  const [selectedLang, setSelectedLang] = useState<string>();
  const { i18n  } = useTranslation();
  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLang(e.target.value);
    localStorage.setItem("lang", e.target.value);
    const lang = localStorage.getItem("lang");
    if (lang === "Arabic") {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useRTL(true);
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useRTL(false);
    }
  };

  const lang = localStorage.getItem("lang");
  useEffect(() => {
    if (lang) {
      setSelectedLang(lang);
      if (lang === "Arabic") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useRTL(true);
        i18n.changeLanguage('ar');
      } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useRTL(false);
        i18n.changeLanguage('en');
      }
    } else {
      setSelectedLang("English");
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useRTL(false)
    }
  }, [lang]);

  return (
    <div className="flex items-center border-2 rounded-3xl p-0.5 px-1  bg-white">
      <span className="text-lg font-bold bg-cyan py-0.5 px-6 rounded-3xl">
        {selectedLang?.charAt(0)}
      </span>
      <select
        value={selectedLang}
        onChange={handleLanguageChange}
        className="p-2 outline-none focus:outline-none focus:ring-0 mx-2"
      >
        <option value="English">English</option>
        <option value="Arabic">Arabic</option>
      </select>
    </div>
  );
};

export default LanguageSelector;
