import axios from "./config";
import { getHeader ,getUserInfo } from "../utility/index";

interface Employee {
  
}

interface ApiResponse {
  data: Employee[];
}
interface DecodedToken {
  emp_id: string; 
}

const getEmployeeList = async (params:any, data:any): Promise<any> => {
  const user_info = getUserInfo();

  const response = await axios.post<any>(`/supervisors/${user_info.emp_id}/activity-report`,data ,  {
    headers: getHeader(),
    params: params
  });
  return response.data;
};
const visitReportList = async (params:any, data:any): Promise<any> => {
  const user_info = getUserInfo();

  const response = await axios.post<any>(`/customer/${user_info.emp_id}/visit-report`,data ,  {
    headers: getHeader(),
    params: params
  });
  return response.data;
};

export{visitReportList}
export default getEmployeeList;

