import React, { useState, useEffect, useRef } from "react";
import DashboardPage from "../../layout/index";
import approveRequest from "../../api/header";
import { useNotifications } from "../../notification";
import GoogleMap from "../../components/GoogleMap";
import moment from "moment";
import BackArrow from "../../assets/back-arrow.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatCurrentEndDate, formatCurrentStartDate } from "../../utility";
import { getMarkerInfo } from "../../api/mapView";

const Notification: React.FC = () => {
  const { notifications, approveNotification } = useNotifications();
  const [locationInfo, setLocationInfo] = useState<any>(null);
  const navigate = useNavigate();
  const [markarData, setMarkarData] = useState<any | null>(null);
  const [selectedMarker, setSelectedMarker] = useState<any | null>(null);

  const handleApprove = async (id: number) => {
    setLocationInfo(null);
    await approveRequest(id, {
      action: "APPROVE",
    })
      .then((res) => {
        approveNotification(id);
        toast.success("Approved");
      })
      .catch((error) => {
        console.log(error);
        approveNotification(id);
        toast.error("Request is expired");
      });
  };
  const handleReject = async (id: number) => {
    setLocationInfo(null);
    await approveRequest(id, {
      action: "REJECT",
    })
      .then((res) => {
        approveNotification(id);
        toast.success("Rejected");
      })
      .catch((error) => {
        console.log(error);
        approveNotification(id);
        toast.error("Request is expired");
      });
  };

  const getLocationData = (locationInfo: any) => {
    const transformedData = {
      position: {
        lat: parseFloat(locationInfo?.employee_latitude),
        lng: parseFloat(locationInfo?.employee_longitude),
      },
      id: locationInfo?.emp_id,
    };
    return transformedData;
  };

  const getCustomerLocation = (locationInfo: any) => {
    const transformedData = {
      position: {
        lat: parseFloat(locationInfo?.customer_latitude),
        lng: parseFloat(locationInfo?.customer_longitude),
      },
      id: locationInfo?.customer_id,
    };

    console.log(locationInfo, "customere is here");
    return transformedData;
  };

  const getMarkerInfoData = async (emp_id: any) => {
    const params = {
      start_date: formatCurrentStartDate(new Date()),
      end_date: formatCurrentEndDate(new Date()),
    };
    await getMarkerInfo(emp_id, params)
      .then((res) => {
        setMarkarData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMarkerClick = (marker: any) => {
    console.log(marker,'marker')
    setMarkarData(null);
    setSelectedMarker(marker);
    getMarkerInfoData(marker?.id);
  };

  const handleCloseInfoWindow = () => {
    setSelectedMarker(null);
  };
  const handleNotificaitocClick = (notif:any) => {
    if(notif?.id=== locationInfo?.id){
      setLocationInfo(null)
    }else{
      setLocationInfo(notif);
    }
  };

  return (
    <DashboardPage>
      <ToastContainer />
      <div className="flex flex-col md:flex-row  ">
        <div className="w-full md:w-[35%]  flex flex-col    border-r-2  mr-4  space-x-4">
          <div className="flex items-center gap-3 p-4  ">
            <div
              className=" flex gap-1 cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <img src={BackArrow} alt="" />
              Back
            </div>
          </div> 
         <div className="md:h-[calc(100vh-120px)] h-[500px] scrollbar scrollbar-thin overflow-x-hidden overflow-y-auto">
         {notifications.length > 0 ? (
            notifications
              ?.slice()
              .reverse()
              .map((notification: any) => {
                return (
                  <div
                    key={notification?.id}
                    className={`cursor-pointer px-4 py-4 pl-4 text-gray-700 hover:bg-gray-100 border-t-2 flex flex-col gap-2 ${
                      notification?.id === locationInfo?.id ? "bg-cyan" : ""
                    }`}
                    
                  >
                    <div className="flex justify-between" onClick={(e) => handleNotificaitocClick(notification)}>
                      {/* <div>Notification : {notification.id}</div> */}
                      <div>
                        {moment(notification?.created_at).format(
                          "D MMM | hh.mA"
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between items-center" >
                      <div onClick={(e) => handleNotificaitocClick(notification)}>You have a new request awaiting review.</div>
                      <div className="flex justify-between gap-2 h-8">
                        <button
                          onClick={() => handleApprove(notification?.id)}
                          className="bg-[#E8FAF5] py-1 rounded px-6 text-[#00C48C] border-[[#00C48C] border-2"
                        >
                          Accept
                        </button>
                        <button
                          onClick={() => handleReject(notification?.id)}
                          className="bg-[#FCEFF0] py-1 rounded px-6 text-[#FF4D68] border-[[#FF4D68] border-2"
                        >
                          Reject
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="px-4 py-2 text-gray-700 flex items-center justify-center w-full">
              No new notifications
            </div>
          )}
         </div>
        </div>
        <div className="w-full md:w-[65%]  h-[300px] md:h-[calc(100vh-54px)]  lg:h-[calc(100vh-54px)] ">
          <GoogleMap
            showNotificationInfo={true}
            customerLoaction={getLocationData(locationInfo)}
            storeLoaction={getCustomerLocation(locationInfo)}
            handleMarkerClick={handleMarkerClick}
            markarData={markarData}
            selectedMarker={selectedMarker}
            handleCloseInfoWindow={handleCloseInfoWindow}
          />
        </div>
      </div>
    </DashboardPage>
  );
};
export default Notification;
