import React, { useEffect, useState } from "react";
import bg from "../../assets/login-bg.svg";
import Mobologo from "../../assets/logo-mobile.svg";

const ImageSection = () => {
  const [isRTL, setIsRTL] = useState(false);
  const dir = document.documentElement.getAttribute("dir");
  useEffect(() => {
    let isRtl = false;
    isRtl = dir === "rtl";
    setIsRTL(isRtl);
  }, [dir]);

  return (
    <>
      <img
        className="hidden md:block w-full h-full bg-cover bg-center object-cover "
        src={bg}
        alt="Background"
        style={{transform: isRTL ? 'scaleX(-1)':'' } }
      />
      <img
        className="md:hidden  w-full h-full bg-cover bg-center object-cover "
        src={Mobologo}
        alt="Background"
      />
    </>
  );
};

export default ImageSection;
