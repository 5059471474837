import Cookies from "js-cookie";
import { getUserInfo } from "../utility";
import React, { createContext, useContext, useEffect, useState } from "react";


let eventSource: EventSource | null = null;

interface NotificationContextType {
  notifications: Notification[];
  approveNotification: (id: number) => void;
}
const NotificationContext = createContext<NotificationContextType | null>(null);

interface NotificationProviderProps {
  children: any;
}
export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<any[]>([]);
  
  const token = Cookies.get("token");
  useEffect(() => {
    if (!eventSource && token) {
      const user_info = getUserInfo();
      const baseUrl = process.env.REACT_APP_DEV_API_URL;
      eventSource = new EventSource(
        `${baseUrl}/supervisors/notifications/${user_info.emp_id}?token=${token}`
      );
      eventSource.onmessage = (event) => {
        const newNotification = JSON.parse(event.data);
        setNotifications((prev) => [...prev, newNotification]);
      };
    }

    return () => {
      if (eventSource) {
        eventSource.close();
        eventSource = null;
      }
    };
  }, [token]);

  const approveNotification = (id: number) => {
    // if(notifications?.length===1){
    //   return setNotifications([])
    // }else{
    // }
    setNotifications((prev) => prev.filter(notification => notification?.id !== id));
};
  return (
    <NotificationContext.Provider value={{ notifications ,approveNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotifications must be used within NotificationProvider"
    );
  }
  return context;
};
